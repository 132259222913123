#gl-statement {
  width: 100%;
  background-color: #000;
  position: relative;
  overflow: hidden;
  display: block
}

#gl-statement p {
  color: #fff;
  font-weight: bold;
  position: relative;
  margin-bottom: 1em;
  width: 100%
}

@media (min-width: 769px) {
  #gl-statement p {
    font-size: 2.5vw;
    line-height: 4.65vw;
  }
}

@media (min-width: 1440px) {
  #gl-statement p {
    font-size: 25px;
    line-height: 45px
  }
}

@media (max-width: 768px) {
  #gl-statement p {
    font-size: 3.73333vw;
    line-height: 6.4vw
  }
}

@media (min-width: 769px) {
  #gl-statement p.large {
    font-size: 5.125vw;
    line-height: 1.2;
  }

  #gl-statement p.large span.indent {
    text-indent: -34px;
  }
}

@media (min-width: 1440px) {
  #gl-statement p.large {
    font-size: 60px;
    line-height: 1.2;
  }
}

@media (max-width: 768px) {
  #gl-statement p.large {
    font-size: 6.13333vw;
  }

  #gl-statement p.large span.indent {
    text-indent: -12px;
  }
}

@media (min-width: 769px) {
  #gl-statement {
    padding: 6vw 12vw 6vw 12vw;
  }
}

@media (min-width: 1440px) {
  #gl-statement {
    padding: 8vw 10% 8vw 36%;
  }
}

@media (max-width: 768px) {
  #gl-statement {
    padding: 8vw 4.66667vw 8vw 4.66667vw;
  }
}

.gl-statement__bg {
  width: 6000px;
  font-size: 350px;
  color: #1e1e1e;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center
}

@media (min-width: 769px) {
  .gl-statement__bg {
    font-size: 24.30556vw
  }
}

@media (min-width: 1440px) {
  .gl-statement__bg {
    font-size: 350px
  }
}

@media (max-width: 768px) {
  .gl-statement__bg {
    font-size: 46.66667vw
  }
}

.gl-statement-txt {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  transform-origin: top left;
}

p.gl-state-intxt {
  color: #fff;
  font-weight: bold;
  position: relative;
  margin-bottom: 1em;
  width: 100%;
}

.gl-state-copyset {
  position: absolute;
  width: 2100px;
  height: 344px;
  top: 50%;
  left: 0;
  margin-top: -172px;
  transform-origin: center left;
  -webkit-user-select: none;
  user-select: none;
}

.gl-state-copy {
  position: absolute;
  width: 2100px;
  height: 344px;
  top: 0;
  left: 0;
  backface-visibility: hidden;
}

.gl-state-white {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  backface-visibility: hidden;
  transition: 0.2s ease-out;
}

.gl-state-intxt span {
  position: relative;
  display: inline-block;
  /*transform:translateY(30px);*/
  backface-visibility: hidden;
  transform-origin: top left;
}

span.gl-state-line {
  display: block;
  /*overflow-y:hidden;*/
  margin: 0;
}

span.indent {
  text-indent: -14px;
}

@media (max-width: 768px) {
  span.indent {
    text-indent: -8px;
  }
}

span.indent-text::second-line {
  text-indent: -14px;
  background: red;
}

.fadeInBottom {
  animation-name: fadeInBottom;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1
  }
}