* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

html {
  height: 100%;
  min-height: 100vh
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: 400
}

ol,
ul,
dl,
dt,
dd {
  list-style: none;
  margin: 0;
  padding: 0
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

table,
thead,
tbody,
tr,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline
}

p {
  margin: 0
}

a {
  text-decoration: none;
  color: #9F9F9F;
  outline: none
}

i {
  font-style: inherit
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  outline: none
}

hr {
  margin: 0;
  border: none
}

[type="text"],
[type="password"],
[type="email"],
[type="number"],
[type="tel"] {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.4
}

[type="checkbox"] {
  margin: 0
}

select {
  outline: none
}

textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

img {
  border: none
}

@-webkit-keyframes line {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%)
  }

  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: hidden
  }

  50% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: hidden
  }

  52% {
    visibility: visible
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%)
  }
}

@keyframes line {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%)
  }

  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: hidden
  }

  50% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: hidden
  }

  52% {
    visibility: visible
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%)
  }
}

@-webkit-keyframes line2 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%)
  }

  49% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: hidden
  }

  50% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: hidden
  }

  52% {
    visibility: visible
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%)
  }
}

@keyframes line2 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%)
  }

  49% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: hidden
  }

  50% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: hidden
  }

  52% {
    visibility: visible
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%)
  }
}

@-webkit-keyframes arrow-hover {
  0% {
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%)
  }

  49% {
    -webkit-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
    visibility: hidden
  }

  50% {
    -webkit-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    visibility: hidden
  }

  52% {
    visibility: visible
  }

  100% {
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%)
  }
}

@keyframes arrow-hover {
  0% {
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%)
  }

  49% {
    -webkit-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
    visibility: hidden
  }

  50% {
    -webkit-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    visibility: hidden
  }

  52% {
    visibility: visible
  }

  100% {
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%)
  }
}

@-webkit-keyframes blank {
  0% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%)
  }

  49% {
    -webkit-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
    visibility: hidden
  }

  50% {
    -webkit-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
    visibility: hidden
  }

  52% {
    visibility: visible
  }

  100% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%)
  }
}

@keyframes blank {
  0% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%)
  }

  49% {
    -webkit-transform: translate(100%, -100%);
    transform: translate(100%, -100%);
    visibility: hidden
  }

  50% {
    -webkit-transform: translate(-100%, 100%);
    transform: translate(-100%, 100%);
    visibility: hidden
  }

  52% {
    visibility: visible
  }

  100% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%)
  }
}

@media (max-width: 768px) {
  .pc-only {
    display: none !important
  }
}

@media (min-width: 769px) {
  .sp-only {
    display: none !important
  }
}

body {
  padding: 0;
  margin: 0;
  font-family: YakuHanJP_Noto, "Noto Sans JP", -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  min-height: 100%;
}

@media (max-width: 768px) {
  body {
    width: 100vw;
    overflow-x: hidden
  }
}

::-moz-selection {
  background-color: #000;
  color: #fff
}

::selection {
  background-color: #000;
  color: #fff
}

body.is-api-loading .wrapper,
body.is-api-loading .menu-button {
  pointer-events: none
}

body.is-api-loading:after {
  content: "";
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000
}

#app {
  background-color: transparent
}

.wrapper {
  background-color: #fff
}

.wrapper .transition-cover {
  content: "";
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 10003;
  opacity: 1;
  pointer-events: none;
  will-change: opacity
}

.wrapper .transition-cover.active {
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear
}

.wrapper .transition-cover.motion {
  opacity: 0
}

.wrapper .transition-cover.black {
  background-color: #000
}

.wrapper .transition-cover.gray {
  background-color: #f6f6f6
}

html.ie .wrapper,
html.edge .wrapper {
  overflow: hidden
}

.is-works .wrapper {
  background-color: #000
}

.container {
  background-color: transparent;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  padding-top: 140px;
  will-change: transform
}

@media (max-width: 768px) {
  .container {
    padding-top: 18.66667vw;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px)
  }
}

.container.active {
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1)
}

.container.motion {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px)
}

.has-contaienr-transition .container {
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.is-home .wrapper,
.is-members .wrapper {
  padding-top: 0
}

.is-home .container,
.is-members .container {
  padding-top: 0
}

.is-home .home-bg {
  display: block
}

.view {
  z-index: 3;
  position: relative;
  background-color: transparent
}

.is-members-detail-open .container {
  -webkit-transform: translateX(-5vw) scale(1) translateZ(0px);
  transform: translateX(-5vw) scale(1) translateZ(0px)
}

.global-header {
  width: 100%;
  height: 140px;
  background-color: #fff;
  padding: 44px 80px 0;
  border-bottom: 1px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000
}

@media (max-width: 768px) {
  .global-header {
    padding: 5.06667vw 4.66667vw 5.06667vw 4.66667vw;
    height: 18.66667vw
  }
}

.global-header .logo {
  width: 152px;
  display: block
}

@media (max-width: 768px) {
  .global-header .logo {
    width: 23.86667vw
  }
}

.global-header .logo img {
  width: 100%;
  height: auto;
  display: block
}

.global-header .logo .black-logo {
  display: block
}

.global-header .logo .white-logo {
  display: none
}

.is-works .container {
  padding-top: 0
}

.is-works .global-header {
  background-color: transparent;
  border-bottom: 1px solid #272727
}

.is-works .global-header .logo .black-logo {
  display: none
}

.is-works .global-header .logo .white-logo {
  display: block
}

.is-members .wrapper {
  padding-top: 0
}

.is-members .global-header {
  background-color: transparent;
  border-bottom: none
}

.is-members .global-header .logo .black-logo {
  display: block
}

.is-members .global-header .logo .white-logo {
  display: none
}

.footer {
  width: 100%;
  background-color: #000;
  text-align: center;
  z-index: 2;
  position: relative;
  will-change: transform
}

.footer-wrapper {
  position: relative
}

.topicpath {
  background-color: #2D2D2D;
  padding: 40px 90px;
  text-align: left;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0;
  z-index: 3;
  position: relative
}

@media (max-width: 768px) {
  .topicpath {
    padding: 6.66667vw 4.66667vw 6.66667vw 4.66667vw
  }
}

.topicpath li {
  font-size: 14px;
  display: inline-block;
  position: relative;
  padding-right: 45px
}

@media (max-width: 768px) {
  .topicpath li {
    font-size: 2.66667vw;
    padding-right: 6.13333vw
  }
}

.topicpath li a {
  color: #fff;
  -webkit-transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.topicpath li a:hover {
  color: #676767
}

.topicpath li:after {
  content: "";
  display: block;
  width: 6px;
  height: 8px;
  /* background-image: url(https://goinc.co.jp/assets/images/common/topicpath-arrow.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .topicpath li:after {
    right: 2.66667vw;
    width: .8vw;
    height: 1.33333vw
  }
}

.topicpath li:last-of-type {
  padding-right: 0
}

.topicpath li:last-of-type:after {
  display: none
}

.topicpath li:last-of-type a {
  color: #676767;
  pointer-events: none
}

.topicpath li span {
  color: #676767
}

.footer-inner {
  background-color: #000;
  will-change: transform
}

@media (max-width: 768px) {
  .footer-inner {
    -webkit-transform: translateY(0px) translateZ(0px) !important;
    transform: translateY(0px) translateZ(0px) !important
  }
}

html.is-ipad .footer-inner {
  -webkit-transform: translateY(0px) translateZ(0px) !important;
  transform: translateY(0px) translateZ(0px) !important
}

.footer__inner {
  padding: 200px 80px 0
}

.footer__inner:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 1440px) {
  .footer__inner {
    padding-top: 13.88889vw
  }
}

@media (max-width: 768px) {
  .footer__inner {
    padding: 45.33333vw 4.66667vw 20.93333vw 4.66667vw
  }
}

.footer-menu {
  float: left;
  text-align: left;
  margin-bottom: 194px
}

@media (max-width: 1440px) {
  .footer-menu {
    margin-bottom: 13.47222vw
  }
}

@media (max-width: 768px) {
  .footer-menu {
    margin-bottom: 0vw;
    float: none
  }
}

.footer-menu__item {
  margin: 0 0 10px
}

@media (max-width: 1440px) {
  .footer-menu__item {
    margin-bottom: .69444vw
  }
}

@media (max-width: 768px) {
  .footer-menu__item {
    margin-bottom: 2.13333vw
  }
}

.footer-menu__item a {
  color: #fff;
  display: block
}

.footer-menu__item span {
  color: #333;
  display: block
}

.footer-menu__item.active {
  pointer-events: none
}

.footer-menu__item.active .normal {
  opacity: 0
}

@media (min-width: 769px) {
  .footer-menu__item a:hover {
    opacity: 0.3
  }
}

.footer-menu__item a {
  position: relative;
  -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.footer-menu__item a,
.footer-menu__item span {
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  height: 58px;
  font-size: 42px;
  font-weight: 500;
}

@media (min-width: 1440px) {

  .footer-menu__item a,
  .footer-menu__item span {
    height: 58px
  }
}

@media (max-width: 1440px) {

  .footer-menu__item a,
  .footer-menu__item span {
    height: 4.02778vw
  }
}

@media (max-width: 768px) {

  .footer-menu__item a,
  .footer-menu__item span {
    width: 47.2vw;
    height: 7.2vw;
    font-size: 28px;
  }
}

.footer-menu__item .active {
  position: absolute;
  top: 0;
  left: 0
}

.footer-menu__item .normal {
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative
}

.footer-menu__item--home a,
.footer-menu__item--home span {
  width: 222px
}

@media (max-width: 1440px) {

  .footer-menu__item--home a,
  .footer-menu__item--home span {
    width: 15.41667vw
  }
}

@media (max-width: 768px) {

  .footer-menu__item--home a,
  .footer-menu__item--home span {
    width: 47.2vw
  }
}

.footer-menu__item--home .normal {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-home.svg) */
}

.footer-menu__item--home .active {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-home-active.svg) */
}

.footer-menu__item--works a,
.footer-menu__item--works span {
  width: 283px
}

@media (max-width: 1440px) {

  .footer-menu__item--works a,
  .footer-menu__item--works span {
    width: 19.65278vw
  }
}

@media (max-width: 768px) {

  .footer-menu__item--works a,
  .footer-menu__item--works span {
    width: 47.2vw
  }
}

.footer-menu__item--works .normal {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-works.svg) */
}

.footer-menu__item--works .active {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-works-active.svg) */
}

.footer-menu__item--members a,
.footer-menu__item--members span {
  width: 379px
}

@media (max-width: 1440px) {

  .footer-menu__item--members a,
  .footer-menu__item--members span {
    width: 26.31944vw
  }
}

@media (max-width: 768px) {

  .footer-menu__item--members a,
  .footer-menu__item--members span {
    width: 47.2vw
  }
}

.footer-menu__item--members .normal {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-members.svg) */
}

.footer-menu__item--members .active {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-members-active.svg) */
}

.footer-menu__item--news a,
.footer-menu__item--news span {
  width: 222px
}

@media (max-width: 1440px) {

  .footer-menu__item--news a,
  .footer-menu__item--news span {
    width: 15.41667vw
  }
}

@media (max-width: 768px) {

  .footer-menu__item--news a,
  .footer-menu__item--news span {
    width: 47.2vw
  }
}

.footer-menu__item--news .normal {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-news.svg) */
}

.footer-menu__item--news .active {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-news-active.svg) */
}

.footer-menu__item--contact a,
.footer-menu__item--contact span {
  width: 360px
}

@media (max-width: 1440px) {

  .footer-menu__item--contact a,
  .footer-menu__item--contact span {
    width: 25vw
  }
}

@media (max-width: 768px) {

  .footer-menu__item--contact a,
  .footer-menu__item--contact span {
    width: 47.2vw
  }
}

.footer-menu__item--contact .normal {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-contact.svg) */
}

.footer-menu__item--contact .active {
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-contact-active.svg) */
}

.is-works-detail .footer-menu__item.footer-menu__item--works.active {
  pointer-events: auto
}

.footer-menu2 {
  float: left;
  text-align: left;
  font-size: 0;
  margin-bottom: 40px
}

@media (max-width: 1440px) {
  .footer-menu2 {
    margin-bottom: 2.77778vw
  }
}

@media (max-width: 768px) {
  .footer-menu2 {
    margin-bottom: 5.33333vw;
    float: none
  }
}

.footer-menu2__item {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  display: inline-block;
  font-size: 20px;
  line-height: 1;
  padding-right: 40px
}

@media (min-width: 1440px) {
  .footer-menu2__item {
    font-size: 20px
  }
}

@media (max-width: 1440px) {
  .footer-menu2__item {
    font-size: 1.38889vw;
    padding-right: 2.77778vw
  }
}

@media (max-width: 768px) {
  .footer-menu2__item {
    font-size: 3.46667vw;
    padding-right: 4.66667vw
  }
}

.footer-menu2__item a {
  color: #fff;
  display: inline-block;
  position: relative;
  padding-left: 16px;
  -webkit-transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (min-width: 769px) {
  .footer-menu2__item a:hover {
    color: #666
  }

  .footer-menu2__item a:hover:before {
    opacity: 0.3
  }
}

.footer-menu2__item a:before {
  content: "";
  display: block;
  width: 6px;
  height: 8px;
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-arrow.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.footer-logo {
  float: right;
  max-width: 501px;
  width: 30vw;
  display: inline-block
}

@media (max-width: 768px) {
  .footer-logo {
    position: absolute;
    top: 15.73333vw;
    right: 4.66667vw;
    width: 53.33333vw;
    float: none
  }
}

.footer-logo img {
  width: 100%;
  height: auto;
  display: block
}

.footer__policy {
  float: right;
  font-size: 0;
  padding-top: 3px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
}

@media (max-width: 1440px) {
  .footer__policy {
    font-size: .97222vw;
    padding-top: .20833vw
  }
}

@media (max-width: 768px) {
  .footer__policy {
    padding-top: 0;
    float: none;
    text-align: left
  }
}

.footer-twitter-link {
  color: #fff;
  position: relative;
  -webkit-transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  display: inline-block;
  font-size: 14px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .footer-twitter-link {
    font-size: 2.66667vw;
  }
}

@media (min-width: 769px) {
  .footer-twitter-link:hover {
    color: #666
  }

  .footer-twitter-link:hover:before {
    opacity: 0.3
  }
}

.footer-twitter-link:before {
  content: "";
  display: block;
  width: 16px;
  height: 14px;
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-twitter-icon.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .footer-twitter-link:before {
    width: 3.06667vw;
    height: 2.66667vw
  }
}

.footer-policy-link {
  color: #fff;
  position: relative;
  padding-left: 16px;
  -webkit-transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  display: inline-block;
  font-size: 14px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .footer-policy-link {
    font-size: 2.66667vw
  }
}

@media (min-width: 769px) {
  .footer-policy-link:hover {
    color: #666
  }

  .footer-policy-link:hover:before {
    opacity: 0.3
  }
}

.footer-policy-link:before {
  content: "";
  display: block;
  width: 6px;
  height: 8px;
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-arrow.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.footer__inner2 {
  padding: 24px 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer__inner2:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 768px) {
  .footer__inner2 {
    padding: 24px 24px 24px 0;
  }
}

.footer__inner3 {
  border-top: 1px solid #272727;
  padding: 62px 80px
}

.footer__inner3:after {
  content: "";
  display: table;
  clear: both
}

.footer__inner3:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 1440px) {
  .footer__inner3 {
    padding-top: 4.30556vw;
    padding-bottom: 4.30556vw
  }
}

@media (max-width: 768px) {
  .footer__inner3 {
    padding: 5.73333vw 4.66667vw 6vw 5.73333vw
  }
}

.footer__copyright {
  float: left;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  color: #fff;
  text-align: left;
  line-height: 26px
}

@media (max-width: 1440px) {
  .footer__copyright {
    font-size: .83333vw;
    line-height: 1.80556vw
  }
}

@media (max-width: 768px) {
  .footer__copyright {
    font-size: 2.4vw;
    line-height: 9.33333vw
  }
}

.footer__circle {
  float: right;
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
  display: inline-block
}

.footer__circle:before {
  content: "";
  display: block;
  border-radius: 50%;
  background-color: #fff;
  width: 26px;
  height: 26px;
  position: absolute;
  top: calc(50% - 13px);
  right: calc(50% - 13px)
}

.footer__circle:after {
  content: "";
  display: block;
  width: 90px;
  height: 90px;
  background-image: url("https://goinc.co.jp/assets/images/common/backtop-arrow-pc.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.3s cubic-bezier(0.16, 1, 0.3, 1)
}

@media (min-width: 769px) {
  .footer__circle:after {
    top: -32px;
    right: -32px
  }
}

@media (max-width: 768px) {
  .footer__circle:after {
    top: 0;
    left: 0;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    background-image: url("https://goinc.co.jp/assets/images/common/backtop-arrow.svg");
    width: 9.33333vw;
    height: 9.33333vw
  }
}

.footer__circle:hover:after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1)
}

@media (max-width: 1440px) {
  .footer__circle {
    width: 1.80556vw;
    height: 1.80556vw
  }
}

@media (max-width: 768px) {
  .footer__circle {
    width: 9.33333vw;
    height: 9.33333vw
  }
}

.main-title {
  display: none
}

#gl-title {
  display: none
}

#gl-statement {
  display: none
}

.home-bg {
  background-color: #f5f5f5;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none
}

.home-bg span {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative
}

.top-logo {
  /* background: url(https://goinc.co.jp/assets/images/home/fv-logo.svg) bottom left no-repeat; */
  background-size: contain;
  width: calc(100% - 80px);
  height: 37.847222222222222vw;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(0px) scale(1) translateZ(0px);
  transform: translateY(0px) scale(1) translateZ(0px)
}

@media (max-width: 768px) {
  .top-logo {
    /* background: url(https://goinc.co.jp/assets/images/home/fv-logo-sp.svg) bottom left no-repeat; */
    width: 100%;
    height: 100%
  }
}

.top-menu {
  text-align: left;
  font-size: 0;
  position: absolute;
  top: 40px;
  left: 55px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0
}

@media (max-width: 768px) {
  .top-menu {
    display: none;
    top: 0;
    left: calc(100% - 18px);
    width: 100vh;
    text-align: center
  }
}

.top-menu.horizontal {
  top: 38px;
  left: 40px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg)
}

.top-menu__item {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding-right: 20px;
  opacity: 0;
  -webkit-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  transform: translateX(-5px)
}

.top-menu__item:nth-of-type(1) {
  -webkit-transition: opacity 0.1s linear, -webkit-transform 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.1s linear, -webkit-transform 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: opacity 0.1s linear, transform 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.1s linear, transform 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.1s linear, transform 0.2s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.2s cubic-bezier(0.16, 1, 0.3, 1)
}

.top-menu__item:nth-of-type(2) {
  -webkit-transition: opacity 0.1s linear 0.02s, -webkit-transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.02s;
  transition: opacity 0.1s linear 0.02s, -webkit-transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.02s;
  -o-transition: opacity 0.1s linear 0.02s, transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.02s;
  transition: opacity 0.1s linear 0.02s, transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.02s;
  transition: opacity 0.1s linear 0.02s, transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.02s, -webkit-transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.02s
}

.top-menu__item:nth-of-type(3) {
  -webkit-transition: opacity 0.1s linear 0.04s, -webkit-transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.04s;
  transition: opacity 0.1s linear 0.04s, -webkit-transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.04s;
  -o-transition: opacity 0.1s linear 0.04s, transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.04s;
  transition: opacity 0.1s linear 0.04s, transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.04s;
  transition: opacity 0.1s linear 0.04s, transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.04s, -webkit-transform 0.2s cubic-bezier(0.16, 1, 0.3, 1) 0.04s
}

@media (max-width: 768px) {
  .top-menu__item {
    font-size: 3.2vw;
    padding-right: 5.33333vw
  }

  .top-menu__item:last-of-type {
    padding-right: 0
  }
}

.top-menu__item a {
  color: #000;
  display: inline-block;
  position: relative;
  padding-left: 16px;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .top-menu__item a {
    padding-left: 2.66667vw
  }
}

@media (min-width: 769px) {
  .top-menu__item a:hover {
    opacity: 0.3
  }
}

.top-menu__item a:before {
  content: "";
  display: block;
  width: 6px;
  height: 8px;
  /* background-image: url(https://goinc.co.jp/assets/images/common/footer-arrow-black.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .top-menu__item a:before {
    width: 1.6vw;
    height: 1.33333vw
  }
}

.is-pc.is-menu-horizontal .top-menu {
  top: 38px;
  left: 40px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg)
}

@-webkit-keyframes top-scroll-animation {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  20% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%)
  }

  30% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%)
  }

  50% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
  }
}

@keyframes top-scroll-animation {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  20% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%)
  }

  30% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%)
  }

  50% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
  }
}

.top-scroll {
  width: 2px;
  height: 80px;
  position: absolute;
  bottom: 0;
  right: 80px;
  background-color: #dddddd;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-transition: opacity 0.5s linear, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.5s linear, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1)
}

@media (max-width: 768px) {
  .top-scroll {
    height: 50px;
    right: 6.93333vw
  }
}

.top-scroll:before {
  content: "";
  display: block;
  width: 2px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000
}

@media (max-width: 768px) {
  .top-scroll:before {
    height: 50px
  }
}

.is-fv-finish .top-menu__item {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.is-fv-finish .top-scroll {
  opacity: 1
}

.is-fv-finish .top-scroll:before {
  -webkit-animation: 2s ease-out 0s infinite top-scroll-animation;
  animation: 2s ease-out 0s infinite top-scroll-animation
}

.is-home .main-title {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  display: block
}

.is-home #gl-title {
  width: 100%;
  height: 100%;
  display: block
}

.is-home #gl-statement {
  width: 100%;
  background-color: #000;
  position: relative;
  overflow: hidden;
  display: block
}

.is-home #gl-statement p {
  color: #fff;
  font-weight: bold;
  position: relative;
  margin-bottom: 1em;
  width: 100%
}

@media (min-width: 769px) {
  .is-home #gl-statement p {
    font-size: 1.73611vw;
    line-height: 3.125vw
  }
}

@media (min-width: 1440px) {
  .is-home #gl-statement p {
    font-size: 25px;
    line-height: 45px
  }
}

@media (max-width: 768px) {
  .is-home #gl-statement p {
    font-size: 3.73333vw;
    line-height: 6.4vw
  }
}

@media (min-width: 769px) {
  .is-home #gl-statement p.large {
    font-size: 3.125vw
  }
}

@media (min-width: 1440px) {
  .is-home #gl-statement p.large {
    font-size: 45px
  }
}

@media (max-width: 768px) {
  .is-home #gl-statement p.large {
    font-size: 6.13333vw
  }
}

@media (min-width: 769px) {
  .is-home #gl-statement {
    padding: 17.01389vw 0vw 16.31944vw 0vw;
    padding-left: 41%
  }
}

@media (min-width: 1440px) {
  .is-home #gl-statement {
    padding: 245px 0 235px 41%
  }
}

@media (max-width: 768px) {
  .is-home #gl-statement {
    padding: 29.33333vw 0vw 29.33333vw 4.66667vw
  }
}

.gl-statement__bg {
  width: 6000px;
  font-size: 350px;
  color: #1e1e1e;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center
}

@media (min-width: 769px) {
  .gl-statement__bg {
    font-size: 24.30556vw
  }
}

@media (min-width: 1440px) {
  .gl-statement__bg {
    font-size: 350px
  }
}

@media (max-width: 768px) {
  .gl-statement__bg {
    font-size: 46.66667vw
  }
}

.showreel {
  width: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-transform: translate3d(0px, -1px, 0px);
  transform: translate3d(0px, -1px, 0px)
}

.showreel:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 56.25%
}

@media (min-width: 769px) {
  .showreel .showreel-playbutton.active .circle {
    width: 90px;
    height: 90px
  }

  .showreel .showreel-playbutton.active .circle:before {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
  }

  .showreel .showreel-playbutton.active .text {
    opacity: 0
  }
}

.showreel-thumbnail {
  position: absolute;
  top: -25vw;
  left: 0;
  width: 100%
}

@media (max-width: 768px) {
  .showreel-thumbnail {
    top: -21.875vw
  }
}

.showreel-thumbnail video {
  display: block;
  width: 100%;
  height: auto
}

.showreel-thumbnail img {
  display: block;
  width: 100%;
  height: auto
}

.showreel-playbutton {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 50px;
  color: #fff;
  letter-spacing: 1px;
  will-change: transform;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal
}

.showreel-playbutton.disabled {
  display: none
}

.showreel-playbutton .text {
  -webkit-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear
}

.showreel-playbutton .circle {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.15s cubic-bezier(0.16, 1, 0.3, 1);
  transition: width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.15s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: transform 0.15s cubic-bezier(0.16, 1, 0.3, 1), width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 0.15s cubic-bezier(0.16, 1, 0.3, 1), width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 0.15s cubic-bezier(0.16, 1, 0.3, 1), width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.15s cubic-bezier(0.16, 1, 0.3, 1)
}

.showreel-playbutton .circle:before {
  content: "";
  display: block;
  width: 26px;
  height: 18px;
  /* background: url(https://goinc.co.jp/assets/images/common/play-button.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

@media (max-width: 768px) {
  .showreel-playbutton {
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important
  }

  .showreel-playbutton .text {
    opacity: 0
  }

  .showreel-playbutton .circle {
    width: 10.66667vw;
    height: 10.66667vw;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }

  .showreel-playbutton .circle:before {
    width: 3.46667vw;
    height: 2.4vw;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
  }
}

html.is-ipad .showreel-playbutton {
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important
}

html.is-ipad .showreel-playbutton .text {
  opacity: 0
}

html.is-ipad .showreel-playbutton .circle {
  width: 90px;
  height: 90px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

html.is-ipad .showreel-playbutton .circle:before {
  width: 26px;
  height: 18px;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1)
}

.showreel-thumbnail:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0
}

.showreel-vimeo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none
}

.showreel-vimeo iframe {
  width: 100%
}

.showreel-vimeo.active {
  opacity: 1;
  pointer-events: auto
}

.who-we-are {
  position: relative;
  padding: 80px 145px;
}

.who-we-are:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 1440px) {
  .who-we-are {
    padding: 15.13889vw 10.06944vw 12.36111vw 10.06944vw
  }
}

@media (max-width: 768px) {
  .who-we-are {
    padding: 8vw 4.66667vw 8vw 4.66667vw
  }
}

.who-we-are h2 {
  font-size: 50px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  width: 40%;
  float: left;
}

@media (max-width: 1440px) {
  .who-we-are h2 {
    font-size: 3.47222vw;
    margin-top: -.76389vw
  }
}

@media (max-width: 768px) {
  .who-we-are h2 {
    width: 100%;
    font-size: 6.66667vw;
    margin: -1.46667vw 0vw 6.53333vw 0vw;
    float: none
  }
}

.who-we-are p {
  width: 60%;
  float: left;
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  margin: -7px 0;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .who-we-are p {
    width: 100%;
    float: none;
    font-size: 3.2vw;
    line-height: 5.6vw;
    margin: -1.2vw 0vw -1.2vw 0vw
  }
}

.home-works {
  color: #fff;
  padding: 50px 0 0;
  position: relative
}

@media (max-width: 768px) {
  .home-works {
    padding-top: unset;
    padding: unset;
  }
}

.home-works-header {
  padding: 0 145px 80px
}

.home-works-header:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 1440px) {
  .home-works-header {
    padding: 0vw 10.06944vw 80px 10.06944vw
  }
}

@media (max-width: 768px) {
  .home-works-header {
    padding: 0vw 4.66667vw 8vw 4.66667vw
  }
}

.home-works-header.active .home-works-title {
  color: #fff
}

.home-works-header.active .home-works-all-link {
  color: #fff
}

.home-works-header.active .home-works-all-link .line {
  background-color: #fff
}

.home-works-header.active .home-works-all-link .arrow:after {
  opacity: 1
}

.home-works-title {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 50px;
  float: left;
  margin: -12px 0;
  color: #000;
  -webkit-transition: color 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 1s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 1440px) {
  .home-works-title {
    font-size: 3.47222vw;
    margin: -.83333vw 0vw -.83333vw 0vw
  }
}

@media (max-width: 768px) {
  .home-works-title {
    font-size: 6.66667vw;
    margin: -1.33333vw 0vw -1.33333vw 0vw
  }
}

.home-works-all-link {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #000;
  float: right;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 12px 30px 12px 0;
  margin: -12px 0 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), color 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), color 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), color 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden;
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px)
}

@media (max-width: 768px) {
  .home-works-all-link {
    font-size: 2.66667vw;
    padding: 2.13333vw 5.6vw 2.13333vw 0vw;
    margin-top: -1.2vw
  }
}

@media (min-width: 769px) {
  .home-works-all-link:hover {
    opacity: 0.6
  }

  .home-works-all-link:hover .line {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }

  .home-works-all-link:hover .arrow:before,
  .home-works-all-link:hover .arrow:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.home-works-all-link .text {
  opacity: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  display: inline-block
}

.home-works-all-link .line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s ease-in-out;
  -o-transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0
}

.home-works-all-link .arrow {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden
}

@media (max-width: 768px) {
  .home-works-all-link .arrow {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.home-works-all-link .arrow:before,
.home-works-all-link .arrow:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px)
}

@media (max-width: 768px) {

  .home-works-all-link .arrow:before,
  .home-works-all-link .arrow:after {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.home-works-all-link .arrow:before {
  /* background: url(https://goinc.co.jp/assets/images/home/works-arrow-black.svg) top left no-repeat; */
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s, -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s
}

.home-works-all-link .arrow:after {
  background: url("https://goinc.co.jp/assets/images/home/works-arrow.svg") top left no-repeat;
  opacity: 0;
  -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s, -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s
}

.home-works-all-link.active .line {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1)
}

.home-works-all-link.active .text {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.home-works-all-link.active .arrow:before,
.home-works-all-link.active .arrow:after {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.home-works-link {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #fff
}

.home-works-item {
  overflow: hidden;
  height: 80vh;
  position: relative;
  display: block
}

@media (min-width: 768px) {
  .home-works-item {
    min-height: 550px;
    max-height: 1111px;
  }
}

@media (max-width: 768px) {
  .home-works-item {
    height: auto
  }
}

.home-works-item:last-of-type .home-works-info {
  border-bottom: none
}

@media (max-width: 768px) {
  .home-works-item.active .home-works-item__image {
    width: 100%;
    opacity: 1
  }
}

.home-works-item.active .cover {
  opacity: 0
}

.home-works-item.active .home-works-info {
  opacity: 1
}

.home-works-item.active2 .home-works-item__image .cover {
  opacity: 0
}

.home-works-item__inner {
  position: relative;
  width: 100%;
  height: 100%
}

@media (max-width: 768px) {
  .home-works-item__inner {
    -webkit-transform: translate3d(0px, 0px, 0px) !important;
    transform: translate3d(0px, 0px, 0px) !important
  }
}

.home-works-item__inner .cover {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  pointer-events: none
}

@media (min-width: 769px) {
  .home-works-item__inner .cover {
    -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
  }
}

@media (max-width: 768px) {
  .home-works-item__inner .cover {
    -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1)
  }
}

html.ie .home-works-item__inner,
html.edge .home-works-item__inner,
html.is-ipad .home-works-item__inner {
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
  transform: translate3d(0px, 0px, 0px) !important
}

.home-works-item__image {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform
}

@media (min-width: 769px) {
  .home-works-item__image {
    width: calc(100% - 400px)
  }
}

@media (max-width: 768px) {
  .home-works-item__image {
    position: relative;
    height: 56.2666666666667vw;
    -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1
  }
}

.home-works-item__image .image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px)
}

.home-works-item__image .image.loaded {
  opacity: 1
}

.home-works-item__image img {
  width: 100%;
  height: auto;
  display: block
}

.home-works-info {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: #222222;
  -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding: 70px;
  border-bottom: 1px solid #000
}

@media (max-width: 768px) {
  .home-works-info {
    position: relative;
    width: 100%;
    height: auto;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    padding: 8vw 4.66667vw 0 4.66667vw;
    opacity: 1;
    border-bottom: none
  }

  .home-works-item:not(:last-child) .home-works-info {
    padding-bottom: 11.46667vw;
  }
}

.home-works-info__logo {
  margin-bottom: 40px
}

@media (max-width: 768px) {
  .home-works-info__logo {
    height: 3.2vw;
    margin-bottom: 5.33333vw
  }

  .home-works-info__logo img {
    width: auto;
    height: 100%;
    display: block
  }
}

.home-works-info__title {
  margin-bottom: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: bold
}

@media (max-width: 768px) {
  .home-works-info__title {
    font-size: 4vw;
    line-height: 5.86667vw;
    margin: -.93333vw 0vw 6.8vw 0vw
  }
}

.home-works-info__text {
  margin-bottom: 40px;
  color: #aaaaaa;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

@media (max-width: 768px) {
  .home-works-info__text {
    font-size: 3.2vw;
    line-height: 5.6vw;
    margin: -1.2vw 0vw 6.26667vw 0vw
  }
}

.home-works-link {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #aaaaaa;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 12px 30px 12px 0;
  margin: -12px 0 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden
}

@media (max-width: 768px) {
  .home-works-link {
    font-size: 2.66667vw;
    padding: 2.13333vw 5.6vw 2.13333vw 0vw;
    margin-top: -1.2vw
  }
}

@media (min-width: 769px) {
  .home-works-link:hover {
    opacity: 0.6
  }

  .home-works-link:hover .line {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }

  .home-works-link:hover .arrow:before {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.home-works-link .text {
  opacity: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  display: inline-block
}

.home-works-link .line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #aaaaaa;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s ease-in-out;
  -o-transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0
}

.home-works-link .arrow {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden
}

@media (max-width: 768px) {
  .home-works-link .arrow {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.home-works-link .arrow:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: url("https://goinc.co.jp/assets/images/home/works-arrow-gray.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s, -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s
}

@media (max-width: 768px) {
  .home-works-link .arrow:before {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.home-works-link.active .line {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1)
}

.home-works-link.active .text {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.home-works-link.active .arrow:before {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.home-works-footer {
  text-align: center;
  background: #222;
  padding: 16px 0vw 16px 0vw
}

@media (max-width: 1440px) {
  .home-works-footer {}
}

@media (max-width: 768px) {
  .home-works-footer {
    padding: unset
  }
}

.home-works-footer__link {
  display: inline-block;
  margin: 0 auto;
  color: #fff;
  font-family: neue-haas-grotesk-text, 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  font-size: 18px;
  padding: 22px 70px 22px 32px;
  border-radius: 30px;
  position: relative;
  -webkit-transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .home-works-footer__link {
    font-size: 3.46667vw;
    padding: 4vw 13.33333vw 4vw 5.33333vw;
    border-radius: 12vw
  }
}

@media (min-width: 769px) {
  .home-works-footer__link:hover {
    /* background-color: #999 */
  }

  .home-works-footer__link:hover span:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.home-works-footer__link span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden;
  filter: invert(1);
}

@media (max-width: 768px) {
  .home-works-footer__link span {
    width: 2.4vw;
    height: 2.4vw;
    right: 5.33333vw
  }
}

.home-works-footer__link span:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url("https://goinc.co.jp/assets/images/home/works-arrow-black.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0
}

@media (max-width: 768px) {
  .home-works-footer__link span:after {
    width: 2.4vw;
    height: 2.4vw
  }
}

.our-business {
  text-align: center;
  position: relative;
  padding: 0 145px
}

@media (max-width: 1440px) {
  .our-business {
    padding: 0vw 10.06944vw 0vw 10.06944vw
  }
}

@media (max-width: 768px) {
  .our-business {
    padding: 0vw 4.66667vw 0vw 4.66667vw
  }
}

.our-business__header {
  position: relative;
  padding: 0 0 80px;
  margin-bottom: 80px;
  border-bottom: 1px solid #cccccc
}

.our-business__header:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 768px) {
  .our-business__header {
    padding-bottom: 8vw;
    margin-bottom: 8vw
  }
}

.our-business__title {
  font-size: 50px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  padding-bottom: 80px;
  color: #000;
  text-align: left;
}

@media (min-width: 769px) {
  .our-business__title {
    float: left;
    width: 40%
  }
}

@media (max-width: 1440px) {
  .our-business__title {
    font-size: 3.47222vw;
    margin-top: -.76389vw
  }
}

@media (max-width: 768px) {
  .our-business__title {
    font-size: 6.66667vw;
    line-height: 2;
    margin: -3.86667vw 0vw 0vw 0vw;
    padding-left: 0;
    padding-bottom: 4vw
  }
}

.our-business__detail {
  text-align: left
}

@media (min-width: 769px) {
  .our-business__detail {
    float: left;
    width: 60%
  }
}

.our-business__title2 {
  width: 100%;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 30px;
  line-height: 1;
  font-weight: 900;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  color: #000;
  margin: -2px 0 40px
}

@media (max-width: 1440px) {
  .our-business__title2 {
    font-size: 2.08333vw;
    margin: -.13889vw 0vw 2.77778vw 0vw
  }
}

@media (max-width: 768px) {
  .our-business__title2 {
    width: 100%;
    display: block;
    font-size: 4.8vw;
    text-align: left;
    padding-bottom: 0;
    margin-bottom: 5.6vw
  }
}

.our-business__title2 span {
  display: inline-block;
  padding-bottom: 12px;
  position: relative
}

@media (max-width: 768px) {
  .our-business__title2 span {
    padding-bottom: 2.13333vw
  }
}

.our-business__title2 span:after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #000;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0
}

@media (max-width: 768px) {
  .our-business__title2 span:after {
    height: .8vw
  }
}

.our-business__text {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  margin: -7px 0;
  font-weight: bold;
  color: #000;
}

@media (max-width: 768px) {
  .our-business__text {
    width: 100%;
    display: block;
    font-size: 3.2vw;
    line-height: 5.6vw;
    text-align: left;
    margin: -1.2vw 0vw -1.2vw 0vw
  }
}

.our-business__inner {
  background-color: #f6f6f6;
  padding: 238px 145px 70px;
  text-align: left
}

@media (max-width: 768px) {
  .our-business__inner {
    padding: 120px 18px 35px
  }
}

.our-creative {
  font-size: 0
}

@media (max-width: 768px) {
  .our-creative {
    padding: 0vw 0vw 0vw 0vw
  }
}

.our-business__buttons {
  margin: 0 -3px 6px;
  margin-bottom: 80px;
}

.our-business__buttons:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 768px) {
  .our-business__buttons {
    margin: 0;
    margin-bottom: 1.33333vw
  }
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

@keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

.our-business__button {
  width: 33.3%;
  height: 280px;
  float: left;
  text-align: center;
  position: relative;
  padding: 0 3px
}

@media (max-width: 1000px) {
  .our-business__button {
    height: 280px
  }
}

@media (max-width: 768px) {
  .our-business__button {
    float: none;
    width: 100%;
    height: 52vw;
    padding: 0;
    margin-bottom: 4.33333vw
  }

  .our-business__button:last-of-type {
    margin-bottom: 0
  }
}

@media (min-width: 769px) {
  .our-business__button:hover .label {
    opacity: 0.7
  }

  .our-business__button:hover:after {
    opacity: 0.7;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
  }

  .our-business__button.active:hover:after {
    -webkit-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    transform: rotate(320deg)
  }
}

.our-business__button a {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #eaeaea;
  background-size: 200% 200%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  -webkit-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear
}

.our-business__button .label {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 700;
  color: #666;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  display: inline-block
}

.our-business__button .label span {
  font-size: 26px;
  line-height: 28px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #000;
  display: inline-block
}

@media (max-width: 1440px) {
  .our-business__button .label {
    font-size: .97222vw
  }

  .our-business__button .label span {
    font-size: 1.80556vw;
    line-height: 1.94444vw
  }
}

@media (max-width: 768px) {
  .our-business__button .label {
    width: 100%;
    font-size: 2.66667vw
  }

  .our-business__button .label span {
    font-size: 5.06667vw;
    line-height: 5.33333vw
  }
}

.our-business__button .hover {
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  overflow-y: scroll;
  scrollbar-width: none;
}

.our-business__button .hover::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.our-business__button .hover span {
  display: inline-block;
  position: absolute;
  width: 74%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
  -ms-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  opacity: 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 768px) {
  .our-business__button .hover span {
    -webkit-transform: translate(-50%, -40%);
    -ms-transform: translate(-50%, -40%);
    transform: translate(-50%, -40%);
    font-size: 3.2vw;
    line-height: 5.6vw
  }
}

.our-business__button:after {
  content: "";
  width: 22px;
  height: 22px;
  background: url("https://goinc.co.jp/assets/images/home/plus-icon-black.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 20px;
  right: 20px;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer
}

@media (max-width: 768px) {
  .our-business__button:after {
    bottom: 2.66667vw;
    right: 2.66667vw;
    width: 4.8vw;
    height: 4.8vw
  }
}

.our-business__button.active .hover {
  opacity: 1
}

.our-business__button.active .hover span {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.our-business__button.active .hover span.jp {
  transform: translate(-50%, 0);
  top: 0%;
  padding: 20px 0;
}

.our-business__button.active:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  /* background-image: url(https://goinc.co.jp/assets/images/home/plus-icon-white.svg) */
}

.rewards {
  font-size: 16px;
  margin-bottom: 16px
}

@media (max-width: 768px) {
  .rewards {
    margin-bottom: 4.33333vw
  }
}

.rewards__left {
  width: 100%;
  background-color: #eaeaea
}

.rewards__left-inner {
  text-align: left;
  width: 100%;
  max-width: 1152px;
  padding: 40px 64px 36px 70px;
  margin: 0 auto
}

@media (min-width: 769px) {
  .rewards__left-inner:after {
    content: "";
    display: table;
    clear: both
  }
}

@media (max-width: 1440px) {
  .rewards__left-inner {
    padding: 2.77778vw 4.44444vw 2.5vw 4.86111vw
  }
}

@media (max-width: 768px) {
  .rewards__left-inner {
    padding: 5.33333vw 5.33333vw 5.33333vw 5.33333vw
  }
}

.rewards__left h4 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  width: 14%;
  float: left
}

@media (max-width: 1440px) {
  .rewards__left h4 {
    font-size: 1.38889vw
  }
}

@media (max-width: 768px) {
  .rewards__left h4 {
    width: 100%;
    font-size: 3.46667vw;
    margin-bottom: 3.2vw;
    float: none
  }
}

.rewards__left p {
  font-size: 16px;
  line-height: 28px;
  margin: -6px 0;
  color: #000;
  font-weight: 700;
  width: 86%;
  float: left;
}

@media (max-width: 1440px) {
  .rewards__left p {
    font-size: 1.11111vw;
    line-height: 1.94444vw;
    margin: -.41667vw 0vw -.41667vw 0vw
  }
}

@media (max-width: 768px) {
  .rewards__left p {
    color: #666666;
    width: 100%;
    font-size: 3.2vw;
    line-height: 5.6vw;
    margin: -1.2vw 0vw -1.2vw 0vw;
    float: none
  }
}

.rewards__right {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #eaeaea;
  padding: 0 8px 10px
}

@media (max-width: 768px) {
  .rewards__right {
    padding: 0vw 1.33333vw 1.33333vw 1.33333vw
  }
}

.rewards__item {
  width: 25%;
  position: relative;
  font-size: 16px;
  line-height: 28px;
  color: #666666;
  font-weight: bold
}

.rewards__item:nth-of-type(1) {
  padding: 0 2px
}

.rewards__item:nth-of-type(2) {
  padding: 0 2px
}

.rewards__item:nth-of-type(3) {
  padding: 0 2px
}

.rewards__item:nth-of-type(4) {
  padding: 0 2px
}

@media (max-width: 1440px) {
  .rewards__item {
    font-size: 1.11111vw
  }
}

@media (min-width: 769px) {
  .rewards__item {
    min-height: 60px
  }
}

@media (max-width: 768px) {
  .rewards__item {
    width: 50%;
    height: 13.33333vw;
    font-size: 3.2vw
  }

  .rewards__item:nth-of-type(1) {
    padding: 0 1px 1px 0
  }

  .rewards__item:nth-of-type(2) {
    padding: 0 0 1px 1px
  }

  .rewards__item:nth-of-type(3) {
    padding: 1px 1px 0 0
  }

  .rewards__item:nth-of-type(4) {
    padding: 1px 0 0 1px
  }
}

.rewards__item .inner {
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100%
}

.rewards__item span {
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 1em
}

.our-services {
  /* padding-bottom: 130px */
}

@media (max-width: 768px) {
  .our-services {
    padding: 8vw 0;
  }
}

.our-services__title {
  text-align: left;
  font-size: 22px;
  line-height: 26px;
  font-weight: 900;
  position: relative;
  padding: 0 0 0 23px;
  margin-bottom: 30px
}

.our-services__title:before {
  content: "";
  display: block;
  height: 26px;
  width: 4px;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .our-services__title:before {
    width: .8vw;
    height: 4vw
  }
}

@media (max-width: 768px) {
  .our-services__title {
    font-size: 4vw;
    line-height: 6.13333vw;
    padding: 0vw 0vw 0vw 3.06667vw;
    margin: -1.06667vw 0vw 4.26667vw 0vw
  }
}

.our-services-list:after {
  content: "";
  display: table;
  clear: both
}

@media (min-width: 769px) {
  .our-services-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -7px 40px;
    padding-top: 10px
  }

  .our-services-list.brands {
    margin: unset;
    padding: 40px 0;
  }
}

@media (max-width: 768px) {
  .our-services-list {
    border-bottom: 0;
    margin-bottom: 10.66667vw
  }
}

.our-services-item {
  width: 33.3%;
  position: relative;
  cursor: pointer
}

@media (min-width: 769px) {
  .our-services-item {
    min-height: 280px;
    padding: 0 7px;
    margin-bottom: 40px
  }

  .our-services-item.brands {
    margin-bottom: unset
  }
}

@media (max-width: 768px) {
  .our-services-item {
    width: 100%;
    border-right-width: 0px;
    height: 49.33333vw;
    overflow: hidden;
    margin-bottom: 5.33333vw;
    padding: 0
  }
}

@media (max-width: 768px) {
  .our-services-item--blank {
    height: 42.93333vw;
    margin-top: -2.66667vw
  }
}

@media (max-width: 768px) {
  .our-services-item--blank .our-services-item__title {
    display: none
  }
}

.our-services-item:last-child {
  border-right-width: 0px
}

@media (max-width: 768px) {
  .our-services-item:last-child {
    margin-bottom: 0
  }
}

.our-services-item__title {
  font-size: 20px;
  font-weight: 900;
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  line-height: 1
}

@media (max-width: 768px) {
  .our-services-item__title {
    font-size: 3.73333vw;
    padding-bottom: 2.66667vw;
    line-height: 4.26667vw;
    margin: 0vw 0vw -.4vw 0vw
  }
}

.our-services-item img {
  width: 100%;
  display: block;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .our-services-item img {
    width: 72%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }
}

@media (min-width: 769px) {
  .our-services-item img {
    max-width: 380px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }
}

.our-services-item__inner {
  position: relative;
  height: 100%;
  border: 1px solid #dddddd;
  background-color: black;
  overflow: hidden;
}

.our-services-item__inner.brands {
  border: unset;
}

@media (max-width: 768px) {
  .our-services-item__inner {
    height: 42.93333vw;
    border: unset;
  }
}

@media (min-width: 769px) {
  .our-services-item__inner {
    height: calc(100% - 40px)
  }
}

.our-services-item.active .hover {
  opacity: 1;
  pointer-events: auto
}

.our-services-item.active .hover-inner {
  opacity: 1
}

@media (min-width: 769px) {
  .our-services-item.active .hover-inner {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px)
  }
}

@media (max-width: 768px) {
  .our-services-item.active .hover-inner {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }
}

.our-services-item.active:after {
  /* background: url(https://goinc.co.jp/assets/images/home/plus-icon-white.svg) top left no-repeat; */
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg)
}

@media (min-width: 769px) {
  .our-services-item:hover img {
    opacity: 0.3
  }

  .our-services-item:hover:after {
    opacity: 0.3;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
  }

  .our-services-item.active:hover:after {
    -webkit-transform: rotate(320deg);
    -ms-transform: rotate(320deg);
    transform: rotate(320deg)
  }
}

.our-services-item .hover-inner {
  width: 100%;
  padding: 0 30px;
  text-align: left;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (min-width: 769px) {
  .our-services-item .hover-inner {
    -webkit-transform: translateY(5%);
    -ms-transform: translateY(5%);
    transform: translateY(5%);
    position: relative
  }
}

@media (max-width: 1440px) {
  .our-services-item .hover-inner {
    padding: 0vw 2.08333vw 0vw 2.08333vw
  }
}

@media (max-width: 768px) {
  .our-services-item .hover-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90.66667vw;
    -webkit-transform: translate(-50%, -45%);
    -ms-transform: translate(-50%, -45%);
    transform: translate(-50%, -45%);
    padding: 0vw 4.66667vw 0vw 4.66667vw
  }
}

.our-services-item .hover-inner--comingsoon {
  height: 100%
}

.our-services-item .hover-comingsoon {
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .our-services-item .hover-comingsoon {
    font-size: 4.8vw
  }
}

.our-services-item .hover {
  background-color: #333;
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  pointer-events: none;
  text-align: left;
  padding: 30px 0
}

@media (max-width: 768px) {
  .our-services-item .hover {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0
  }
}

.our-services-item .hover .text {
  display: block;
  font-size: 14px;
  line-height: 24px;
  margin: -5px 0 22px;
}

.our-services-item.brands .hover .text {
  display: block;
  font-size: 14px;
  line-height: 24px;
}

.our-services-item.brands .hover .text:after {
  content: unset;
}

.our-services-item.brands .hover a {
  margin: unset;
}

.our-services-item .hover .text:after {
  content: "";
  display: block;
  width: 12px;
  height: 10px;
  background: url("https://goinc.co.jp/assets/images/home/blank-white-icon.svg") top left no-repeat;
  background-size: contain
}

.our-services-item .hover .text {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: unset;
}

.our-services-item__inner .hover .hover-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .our-services-item .hover .text {
    font-size: 3.2vw;
    line-height: 5.6vw;
    margin: -.66667vw 0vw 1.73333vw 0vw
  }
}

.our-services-item .hover a {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 12px 30px 12px 0;
  margin: -12px 0 0;
  overflow: hidden
}

@media (min-width: 769px) {
  .our-services-item .hover a:hover span {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

@media (max-width: 768px) {
  .our-services-item .hover a {
    font-size: 2.66667vw;
    padding: 2.13333vw 5.33333vw 2.13333vw 0vw;
    margin-top: -1.2vw
  }
}

.our-services-item .hover a span {
  width: 100%;
  height: 1px;
  background-color: #fff;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0
}

.our-services-item .hover a:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: url("https://goinc.co.jp/assets/images/home/blank-white-icon.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .our-services-item .hover a:after {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.our-services-item:after {
  content: "";
  width: 22px;
  height: 22px;
  /* background: url(https://goinc.co.jp/assets/images/home/plus-icon-black.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  bottom: 20px;
  right: 20px;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .our-services-item:after {
    bottom: 2.66667vw;
    right: 2.66667vw;
    width: 4.8vw;
    height: 4.8vw
  }
}

.business-plan-link {
  background-color: #000;
  display: inline-block;
  margin: 0 auto;
  color: #fff;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 900;
  font-style: normal;
  line-height: 1;
  padding: 22px 54px 22px 35px;
  border-radius: 30px;
  position: relative;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), color 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), color 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), color 1s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .business-plan-link {
    font-size: 3.2vw;
    padding: 3.73333vw 10.66667vw 3.73333vw 5.6vw;
    border-radius: 12vw
  }
}

@media (min-width: 769px) {
  .business-plan-link:hover {
    opacity: 0.3
  }

  .business-plan-link:hover span:before,
  .business-plan-link:hover span:after {
    -webkit-animation: blank 0.35s ease-in-out;
    animation: blank 0.35s ease-in-out
  }
}

.business-plan-link.active {
  background-color: #fff;
  color: #000
}

.business-plan-link.active span:after {
  opacity: 1
}

.business-plan-link span {
  display: block;
  position: absolute;
  top: 50%;
  right: 32px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 12px;
  height: 10px;
  overflow: hidden
}

.business-plan-link span:before {
  content: "";
  display: block;
  width: 12px;
  height: 10px;
  background: url("https://goinc.co.jp/assets/images/home/blank-white-icon.svg") top left no-repeat;
  background-size: contain
}

.business-plan-link span:after {
  content: "";
  display: block;
  width: 12px;
  height: 10px;
  background: url("https://goinc.co.jp/assets/images/home/blank-icon.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {

  .business-plan-link span:before,
  .business-plan-link span:after {
    width: 2.66667vw;
    height: 2.13333vw
  }
}

@media (max-width: 768px) {
  .business-plan-link span {
    width: 2.66667vw;
    height: 2.13333vw;
    right: 5.73333vw
  }
}

.our-client {
  position: relative
}

.our-client__content {
  background-color: #fff;
  width: 100%;
  position: relative;
  padding: 80px 145px;
}

@media (max-width: 1440px) {
  .our-client__content {
    padding: 13.88889vw 10vw 11.11111vw 10vw
  }
}

@media (max-width: 768px) {
  .our-client__content {
    width: 100%;
    padding: 8vw 0 8vw 0;
  }
}

.our-client__title {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 35px;
  position: relative;
  display: inline-block;
  line-height: 2;
  margin: -20px 0 40px
}

@media (max-width: 1440px) {
  .our-client__title {
    font-size: 2.43056vw;
    margin: -1.38889vw 0vw 2.77778vw 0vw
  }
}

@media (max-width: 768px) {
  .our-client__title {
    font-size: 6.66667vw;
    margin: -3.86667vw 0vw 1.33333vw 0vw;
    padding-left: 4.66667vw
  }
}

.our-client__attention {
  color: #666666;
  line-height: 1;
  font-size: 14px;
  padding: 20px 0 0
}

@media (max-width: 1440px) {
  .our-client__attention {
    font-size: .97222vw;
    margin: 1.38889vw 0vw 0vw 0vw
  }
}

@media (max-width: 768px) {
  .our-client__attention {
    padding: 4.8vw 4.66667vw 0vw 4.66667vw;
    font-size: 2.66667vw
  }
}

.our-client-list {
  font-size: 0
}

.our-client-item {
  display: inline-block;
  width: 20%;
  text-align: center;
  vertical-align: top;
  position: relative;
  text-align: center
}

.our-client-item img {
  vertical-align: middle;
  width: 100%;
  max-width: 210px;
  height: auto;
  display: inline-block
}

@media (max-width: 1200px) {
  .our-client-item {
    width: 25%
  }
}

@media (max-width: 768px) {
  .our-client-item {
    width: 33.3%
  }
}

.home-news {
  background-color: #f6f6f6;
  padding: 80px 144px;
  position: relative
}

.home-news:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 1440px) {
  .home-news {
    padding: 13.88889vw 0vw 13.88889vw 10vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .home-news-content {
    width: 70% !important;
    float: unset;
  }
}

@media (max-width: 768px) {
  .home-news {
    padding: 8vw 4.66667vw;
    display: block;
    align-items: unset;
    justify-content: unset;
  }

  .home-news-content {
    width: 100% !important;
  }
}

.home-news-header {
  width: 15%;
  float: left;
  margin-bottom: auto;
}

@media (max-width: 768px) {
  .home-news-header {
    float: none;
    position: relative;
    width: 100%;
    margin-bottom: 6vw;
    margin-top: 24px;
  }
}

.home-news-title {
  font-size: 35px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
}

@media (max-width: 768px) {
  .home-news-title {
    font-size: 6.66667vw;
    margin: -.53333vw 0vw -.8vw 0vw;
    line-height: 1
  }
}

.home-news-link {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #000;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 12px 30px 12px 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden
}

@media (max-width: 768px) {
  .home-news-link {
    font-size: 2.66667vw;
    position: absolute;
    top: 0;
    right: 4.66667vw;
    padding: 1.86667vw 5.6vw 1.6vw 0vw;
    margin-top: -1.06667vw
  }
}

@media (min-width: 769px) {
  .home-news-link:hover {
    opacity: 0.3
  }

  .home-news-link:hover .line {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }

  .home-news-link:hover .arrow:before {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.home-news-link .text {
  opacity: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  display: inline-block
}

.home-news-link .line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s ease-in-out;
  -o-transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0
}

.home-news-link .arrow {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden
}

@media (max-width: 768px) {
  .home-news-link .arrow {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.home-news-link .arrow:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: url("https://goinc.co.jp/assets/images/home/works-arrow-black.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s, -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s
}

@media (max-width: 768px) {
  .home-news-link .arrow:before {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.home-news-link.active .line {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1)
}

.home-news-link.active .text {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.home-news-link.active .arrow:before {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.home-news-content {
  float: left;
  width: 85%
}

.home-news-content {
  /* height: 135.5px; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: black; */
}

@media (max-width: 768px) {
  .home-news-content {
    float: none;
    width: 100%
  }
}

.home-news-list {
  padding-bottom: 90px;
  font-size: 0;
  width: 100%;
  margin-bottom: 40px
}

@media (max-width: 768px) {
  .home-news-list {
    width: 100%;
    padding-bottom: 10.66667vw;
    margin-bottom: 8vw
  }
}

.home-news-list .ps__rail-x {
  height: 2px !important;
  background-color: #dddddd !important;
  opacity: 1 !important
}

.home-news-list .ps__thumb-x {
  background-color: #000;
  height: 2px !important;
  bottom: 0 !important
}

.home-news-list .news-item {
  width: 410px;
  margin-right: 0;
  padding-right: 90px
}

@media (max-width: 768px) {
  .home-news-list .news-item {
    width: 70.66667vw;
    padding-right: 10.66667vw
  }
}

html.ie .home-news-list .news-item__date::-moz-selection {
  background-color: transparent !important;
  color: #aaaaaa
}

html.ie .home-news-list .news-item__date::selection {
  background-color: transparent !important;
  color: #aaaaaa
}

html.ie .home-news-list .news-item__text::-moz-selection {
  background-color: transparent !important;
  color: #000
}

html.ie .home-news-list .news-item__text::selection {
  background-color: transparent !important;
  color: #000
}

.home-news-list__container {
  width: 2050px
}

@media (max-width: 768px) {
  .home-news-list__container {
    width: 353.333333333333333vw
  }
}

.swiper-container-horizontal>.swiper-scrollbar {
  width: calc(100% - 80px);
  left: 0;
  bottom: 0;
  z-index: 50;
  height: 2px
}

@media (max-width: 768px) {
  .swiper-container-horizontal>.swiper-scrollbar {
    width: calc(100% - 4.666666666666667vw)
  }
}

.swiper-scrollbar-drag {
  border-radius: 0;
  background-color: #000
}

.home-news-navi {
  font-size: 0
}

.home-news-prev,
.home-news-next {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  display: inline-block;
  cursor: pointer
}

@media (max-width: 768px) {

  .home-news-prev,
  .home-news-next {
    width: 9.33333vw;
    height: 9.33333vw
  }
}

@media (min-width: 769px) {

  .home-news-prev:hover span,
  .home-news-next:hover span {
    opacity: 0.3
  }

  .home-news-prev:hover span:after,
  .home-news-next:hover span:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.home-news-prev span,
.home-news-next span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {

  .home-news-prev span,
  .home-news-next span {
    width: 2.4vw;
    height: 2.4vw;
    right: 5.33333vw
  }
}

.home-news-prev span:after,
.home-news-next span:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  /* background: url(https://goinc.co.jp/assets/images/home/works-arrow-black.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0
}

@media (max-width: 768px) {

  .home-news-prev span:after,
  .home-news-next span:after {
    width: 2.4vw;
    height: 2.4vw
  }
}

.home-news-prev.swiper-button-disabled,
.home-news-next.swiper-button-disabled {
  pointer-events: none
}

.home-news-prev.swiper-button-disabled span,
.home-news-next.swiper-button-disabled span {
  opacity: 0.1
}

.home-news-prev {
  margin-right: 8px
}

.home-news-prev span {
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
  -ms-transform: translate(-50%, -50%) rotate(180deg);
  transform: translate(-50%, -50%) rotate(180deg)
}

@media (max-width: 768px) {
  .home-news-prev {
    margin-right: 1.33333vw
  }
}

.home-other {
  background-color: #f6f6f6;
  font-size: 0
}

.home-other-item {
  width: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden
}

@media (max-width: 768px) {
  .home-other-item {
    width: 100%
  }
}

.home-other-item a {
  cursor: pointer
}

.home-other-item__image {
  position: relative
}

.home-other-item__image:before {
  content: "";
  display: block;
  padding-bottom: 70%;
  width: 100%
}

.home-other-item__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0
}

.home-other-item__image img.loaded {
  opacity: 1
}

.home-other-item__cover {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0
}

@media (min-width: 769px) {
  .home-other-item:hover .home-other-item__cover {
    opacity: 1
  }

  .home-other-item:hover .home-other-item__image img {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02)
  }

  .home-other-item:hover .home-other-item__title {
    color: #999
  }

  .home-other-item:hover .home-other-item__title span {
    opacity: 0.3
  }

  .home-other-item:hover .home-other-item__title span:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.home-other-item img {
  width: 100%;
  height: auto;
  display: block;
  filter: grayscale(1);
}

.home-other-item__title {
  background-color: #fff;
  -webkit-transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  display: inline-block;
  margin: 0 auto;
  color: #000;
  font-family: neue-haas-grotesk-text, 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  font-size: 12px;
  padding: 18px 60px 18px 24px;
  border-radius: 30px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .home-other-item__title {
    padding: 4vw 14.13333vw 4vw 5.33333vw;
    font-size: 3.46667vw;
    bottom: 4.66667vw;
    left: 4.66667vw;
    border-radius: 12vw
  }
}

.home-other-item__title span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden;
  -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .home-other-item__title span {
    width: 2.4vw;
    height: 2.4vw;
    right: 5.33333vw
  }
}

.home-other-item__title span:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url("https://goinc.co.jp/assets/images/home/works-arrow-black.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0
}

@media (max-width: 768px) {
  .home-other-item__title span:after {
    width: 2.4vw;
    height: 2.4vw
  }
}

.home-gear {
  background-color: #fff;
  display: block
}

@media (min-width: 769px) {
  .home-gear {
    padding: 138px 0 0
  }
}

@media (max-width: 768px) {
  .home-gear {
    padding: 8vw 4.66667vw 8vw 4.66667vw
  }
}

.home-gear__image.active {
  background-image: url("../../assets/comingSoon.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
}

.home-gear__image.active img {
  opacity: 0 !important;
}

.home-gear__image img {
  transition: 0.3s ease !important;
}

.home-gear__image {
  position: relative;
  overflow: hidden
}

.home-gear__image:before {
  content: "";
  display: block;
  padding-bottom: 19.444444444444444%;
  width: 100%
}

@media (max-width: 768px) {
  .home-gear__image:before {
    padding-bottom: 26.470588235294118%
  }
}

.home-gear__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0
}

.home-gear__image img.sp-only {
  opacity: 1
}

.home-gear__image img.loaded {
  opacity: 1
}

.home-gear a {
  display: block;
  width: 100%;
  position: relative
}

@media (min-width: 1151px) {
  .home-gear a {
    width: 1151px;
    margin: 0 auto
  }
}

@media (max-width: 768px) {
  .home-gear a {
    max-width: 100%
  }
}

@media (min-width: 769px) {
  .home-gear a:hover {
    color: #999
  }

  .home-gear a:hover .home-gear__image img {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02)
  }

  .home-gear a:hover span {
    opacity: 0.3
  }

  .home-gear a:hover span:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.home-gear img {
  width: 100%;
  height: auto;
  display: block;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1)
}

.home-gear .arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 64px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .home-gear .arrow {
    display: none
  }
}

.home-gear .arrow span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.home-gear .arrow span:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url("https://goinc.co.jp/assets/images/home/works-arrow-black.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0
}

.home-info {
  background-color: #fff;
  padding: 80px 144px;
  font-size: 0;
  position: relative
}

@media (max-width: 1440px) {
  .home-info {
    padding: 18.75vw 10vw 11.11111vw 10vw
  }
}

@media (max-width: 768px) {
  .home-info {
    padding: 0 4.66667vw 8vw 4.66667vw
  }
}

.home-info__title {
  font-size: 35px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  width: 50%;
  display: inline-block;
  vertical-align: top;
  line-height: 1.5;
  margin: -12px 0 0
}

@media (max-width: 1440px) {
  .home-info__title {
    font-size: 2.43056vw;
    margin: -.83333vw 0vw 0vw 0vw
  }
}

@media (max-width: 768px) {
  .home-info__title {
    font-size: 6.66667vw;
    width: 100%;
    margin: -2.13333vw 0vw 7.2vw 0vw
  }
}

.home-info-content {
  width: 25%;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  margin: 0 0 110px 0
}

@media (max-width: 1440px) {
  .home-info-content {
    margin: 0vw 0vw 7.63889vw 0vw
  }
}

@media (max-width: 768px) {
  .home-info-content {
    padding-left: 0;
    margin-bottom: 0;
    width: 100% !important
  }
}

.home-info-content dl {
  font-weight: bold
}

@media (max-width: 768px) {
  .home-info-content dl:after {
    content: "";
    display: table;
    clear: both
  }
}

.home-info-content dt {
  color: #999;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 13px;
  line-height: 1.5;
  margin: -4px 0 0
}

@media (max-width: 1440px) {
  .home-info-content dt {
    font-size: .97222vw;
    margin: -.27778vw 0vw 0vw 0vw;
    padding-bottom: .90278vw
  }
}

@media (max-width: 768px) {
  .home-info-content dt {
    color: #666;
    font-size: 2.66667vw;
    margin: -.66667vw 0vw -.66667vw 0vw;
    padding-bottom: 3.06667vw;
    width: 100%;
    float: left
  }
}

.home-info-content dd {
  font-size: 16px;
  padding-bottom: 40px;
  line-height: 1.5;
  margin: -4px 0 0
}

@media (max-width: 1440px) {
  .home-info-content dd {
    font-size: 1.11111vw;
    margin: -.27778vw 0vw 0vw 0vw;
    padding-bottom: 2.77778vw
  }
}

@media (max-width: 768px) {
  .home-info-content dd {
    font-size: 3.2vw;
    margin: -.8vw 0vw -.8vw 0vw;
    padding-bottom: 5.86667vw;
    width: 80%;
    float: left
  }

  .home-info-content dd:last-of-type {
    padding-bottom: 4.53333vw
  }
}

.home-info-content p {
  font-size: 14px;
  line-height: 24px;
  margin: -5px 0
}

@media (max-width: 1440px) {
  .home-info-content p {
    font-size: .97222vw;
    line-height: 1.66667vw;
    margin: -.34722vw 0vw -.34722vw 0vw
  }
}

@media (max-width: 768px) {
  .home-info-content p {
    font-size: 2.93333vw;
    line-height: 1.5;
    margin: -.8vw 0vw 9.86667vw 0vw;
    width: 100%;
    padding-left: 20%
  }
}

.home-info__address {
  padding-bottom: 5px;
  display: block;
  line-height: 1.5
}

@media (max-width: 1440px) {
  .home-info__address {
    padding-bottom: .34722vw
  }
}

@media (max-width: 768px) {
  .home-info__address {
    padding-bottom: 1.33333vw
  }
}

.home-info__googlemap-link {
  font-size: 16px;
  color: #000;
  padding-left: 18px;
  position: relative;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  display: block;
  line-height: 1
}

@media (max-width: 1440px) {
  .home-info__googlemap-link {
    font-size: 1.11111vw;
    padding-left: 1.25vw
  }
}

@media (max-width: 768px) {
  .home-info__googlemap-link {
    font-size: 3.2vw;
    padding-left: 4vw
  }
}

@media (min-width: 769px) {
  .home-info__googlemap-link:hover {
    opacity: 0.3
  }

  .home-info__googlemap-link:hover .line {
    -webkit-animation: line 0.5s ease-in-out;
    animation: line 0.5s ease-in-out
  }
}

.home-info__googlemap-link:before {
  content: "";
  display: block;
  width: 11px;
  height: 17px;
  background: url("https://goinc.co.jp/assets/images/home/map-icon.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 1440px) {
  .home-info__googlemap-link:before {
    width: .76389vw;
    height: 1.18056vw
  }
}

@media (max-width: 768px) {
  .home-info__googlemap-link:before {
    width: 2.4vw;
    height: 3.6vw
  }
}

.home-info__googlemap-link .text {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  overflow: hidden
}

.home-info__googlemap-link .text .line {
  width: 100%;
  height: 1px;
  background-color: #000;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0
}

.home-contact {
  background-color: #f5f5f5;
  display: block;
  width: 100%;
  border-radius: 10px;
  font-size: 30px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal
}

@media (max-width: 1440px) {
  .home-contact {
    font-size: 2.08333vw
  }
}

@media (max-width: 768px) {
  .home-contact {
    font-size: 4.66667vw
  }
}

.home-contact a {
  display: block;
  color: #000;
  padding: 76px;
  position: relative;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  cursor: pointer
}

@media (max-width: 768px) {
  .home-contact a {
    padding: 9.06667vw 8vw 9.06667vw 8vw
  }
}

@media (min-width: 769px) {
  .home-contact a:hover {
    color: #999
  }

  .home-contact a:hover span {
    opacity: 0.3
  }

  .home-contact a:hover span:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.home-contact .arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 64px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .home-contact .arrow {
    width: 9.33333vw;
    height: 9.33333vw;
    right: 7.73333vw
  }
}

.home-contact .arrow span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .home-contact .arrow span {
    width: 2.4vw;
    height: 2.4vw;
    right: 5.33333vw
  }
}

.home-contact .arrow span:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url("https://goinc.co.jp/assets/images/home/works-arrow-black.svg") top left no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0
}

@media (max-width: 768px) {
  .home-contact .arrow span:after {
    width: 2.4vw;
    height: 2.4vw
  }
}

.navigation-drag {
  font-size: 16px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 110px;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: opacity 0.3s linear, visibility 0.3s linear;
  -o-transition: opacity 0.3s linear, visibility 0.3s linear;
  transition: opacity 0.3s linear, visibility 0.3s linear;
  display: none;
  pointer-events: none;
  opacity: 0
}

.navigation-drag.show {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  will-change: transform, opacity
}

.navigation-drag.active {
  display: block
}

@media (max-width: 768px) {
  .navigation-drag {
    display: none !important
  }
}

.parallax-showreel {
  position: fixed;
  visibility: hidden;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000
}

.parallax-showreel:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 100%
}

.parallax-showreel img {
  display: none;
  width: 100%;
  height: auto
}

.parallax-showreel video {
  display: block;
  width: 100%;
  height: auto;
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0
}

.parallax-showreel:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0
}

.parallax-works {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: calc(100% - 400px);
  height: 80vh;
  will-change: transform
}

.parallax-works .image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.parallax-works .image.loaded {
  opacity: 1
}

.parallax-works img {
  width: 100%;
  height: auto;
  display: block
}

.parallax-works .cover {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.error {
  background-color: #fff;
  padding: 180px 0 180px
}

@media (max-width: 768px) {
  .error {
    padding: 32vw 0vw 32vw 0vw
  }
}

.error-header {
  padding: 0 80px 80px
}

.error-header h1 {
  font-size: 50px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -12px 0
}

@media (max-width: 768px) {
  .error-header h1 {
    font-size: 6.66667vw;
    line-height: 7.33333vw;
    margin: -.8vw 0vw -.8vw 0vw
  }
}

@media (max-width: 768px) {
  .error-header {
    padding: 0vw 4.66667vw 4.66667vw 4.66667vw
  }
}

.error-body {
  padding: 0 80px
}

@media (max-width: 768px) {
  .error-body {
    padding: 0vw 4.66667vw 0vw 4.66667vw
  }
}

.news {
  background-color: #f6f6f6;
  padding: 190px 0 260px
}

@media (max-width: 768px) {
  .news {
    padding: 32vw 0vw 20vw 0vw
  }
}

.news-header {
  padding: 0 80px 80px
}

.news-header h1 {
  font-size: 80px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -20px 0
}

@media (max-width: 768px) {
  .news-header h1 {
    font-size: 9.33333vw;
    margin: -2.13333vw 0vw -2.13333vw 0vw
  }
}

@media (max-width: 768px) {
  .news-header {
    padding: 0vw 4.66667vw 22.66667vw 4.66667vw
  }
}

.news-border {
  display: inline-block;
  width: 33.3%;
  height: 1px;
  position: relative
}

.news-border:before {
  content: "";
  width: calc(100% - 64px);
  height: 1px;
  background-color: #dddddd;
  display: block;
  position: absolute;
  top: 0;
  left: 32px
}

@media (max-width: 768px) {
  .news-border {
    display: none
  }
}

.news-list {
  padding: 0 58px 150px;
  font-size: 0
}

@media (max-width: 768px) {
  .news-list {
    padding: 0vw 4.66667vw 9.33333vw 4.66667vw
  }
}

.news-list .news-item {
  width: 33.3%;
  padding: 80px 32px 60px;
  margin: 0;
  position: relative
}

@media (min-width: 769px) {
  .news-list .news-item:before {
    content: "";
    width: calc(100% - 64px);
    height: 1px;
    background-color: #dddddd;
    position: absolute;
    top: 0;
    left: 32px
  }

  .news-list .news-item:nth-of-type(1):before {
    display: none
  }

  .news-list .news-item:nth-of-type(2):before {
    display: none
  }

  .news-list .news-item:nth-of-type(3):before {
    display: none
  }
}

@media (max-width: 768px) {
  .news-list .news-item {
    width: 100%;
    padding: 0vw 0vw 8vw 0vw;
    margin-bottom: 10.66667vw
  }

  .news-list .news-item:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #dddddd;
    position: absolute;
    bottom: 0;
    left: 0
  }
}

.news-item {
  width: 384px;
  display: inline-block;
  margin-right: 90px;
  vertical-align: top
}

@media (min-width: 769px) {
  .news-item:hover .news-item__link {
    color: #999
  }

  .news-item:hover .news-item__link:after {
    opacity: 0.3
  }
}

.news-item__date {
  font-size: 16px;
  line-height: 1;
  margin-bottom: 8px;
  color: #aaaaaa;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@media (max-width: 768px) {
  .news-item__date {
    font-size: 4.2vw;
  }
}

.news-item__link {
  font-size: 14px;
  color: #000;
  text-decoration: underline;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 12px;

  display: block;
  -webkit-transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.news-item__link:after {
  content: "";
  display: inline-block;
  /* background: url(https://goinc.co.jp/assets/images/home/blank-icon.svg) top right no-repeat; */
  background-size: contain;
  width: 16px;
  height: 11px;
  padding-left: 4px
}

@media (max-width: 768px) {
  .news-item__link {
    font-size: 3.2vw;
    line-height: 1.5;
    margin-bottom: 1.5vw;
  }

  .news-item__link:after {
    width: 4vw;
    height: 2.13333vw;
    padding-left: 1.33333vw
  }
}

.news-item__text {
  font-size: 14px;
  line-height: 24px;
  margin: -5px 0;
}

@media (max-width: 768px) {
  .news-item__text {
    font-size: 3.2vw;
    line-height: 5.6vw;
    margin: -1.2vw 0vw -1.2vw 0vw
  }
}

.news-pagenation {
  font-size: 0;
  width: 100%;
  text-align: center
}

.news-pagenation-list {
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  margin-right: 40px
}

@media (max-width: 768px) {
  .news-pagenation-list {
    margin-right: 7.2vw
  }
}

.news-pagenation-item {
  display: inline-block;
  width: 50px;
  height: 60px;
  text-align: center;
  font-size: 20px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  vertical-align: top;
  line-height: 60px
}

@media (max-width: 768px) {
  .news-pagenation-item {
    font-size: 3.33333vw;
    line-height: 9.33333vw;
    width: 8vw;
    height: 9.33333vw
  }
}

.news-pagenation-item a {
  color: #000;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  pointer-events: auto;
  cursor: pointer
}

@media (min-width: 769px) {
  .news-pagenation-item a:hover {
    color: #999
  }
}

.news-pagenation-item a.active {
  pointer-events: none !important;
  color: #999 !important;
  cursor: default !important
}

.news-prev,
.news-next {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  display: inline-block;
  cursor: pointer;
  pointer-events: none
}

@media (max-width: 768px) {

  .news-prev,
  .news-next {
    width: 9.33333vw;
    height: 9.33333vw
  }
}

.news-prev.active,
.news-next.active {
  pointer-events: auto
}

.news-prev.active span,
.news-next.active span {
  opacity: 1
}

@media (min-width: 769px) {

  .news-prev.active:hover span,
  .news-next.active:hover span {
    opacity: 0.3
  }

  .news-prev.active:hover span:after,
  .news-next.active:hover span:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.news-prev span,
.news-next span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
  opacity: 0.3;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {

  .news-prev span,
  .news-next span {
    width: 2.4vw;
    height: 2.4vw;
    right: 5.33333vw
  }
}

.news-prev span:after,
.news-next span:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  /* background: url(https://goinc.co.jp/assets/images/home/works-arrow-black.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0
}

@media (max-width: 768px) {

  .news-prev span:after,
  .news-next span:after {
    width: 2.4vw;
    height: 2.4vw
  }
}

.news-prev {
  margin-right: 40px
}

@media (max-width: 768px) {
  .news-prev {
    margin-right: 7.2vw
  }
}

.news-prev span {
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
  -ms-transform: translate(-50%, -50%) rotate(180deg);
  transform: translate(-50%, -50%) rotate(180deg)
}

.works {
  background-color: #000;
  padding: 140px 0 0
}

@media (max-width: 768px) {
  .works {
    padding-top: 18.66667vw
  }
}

.works-header {
  padding: 190px 80px 150px
}

.works-header:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 768px) {
  .works-header {
    padding: 25.33333vw 4.66667vw 13.86667vw 4.66667vw
  }
}

.works-header h1 {
  font-size: 80px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -20px 0;
  color: #fff;
  float: none
}

@media (max-width: 768px) {
  .works-header h1 {
    font-size: 9.33333vw;
    margin: -2.4vw 0vw -2.4vw 0vw;
    float: none
  }
}

.works-header ul {
  font-size: 0;
  padding-top: 50px;
  float: none
}

@media (max-width: 768px) {
  .works-header ul {
    padding-top: 6.66667vw;
    float: none
  }
}

.works-header li {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px
}

@media (max-width: 768px) {
  .works-header li {
    margin-left: 0;
    margin-right: 1.33333vw;
    margin-bottom: 1.33333vw
  }
}

.works-header li a {
  font-size: 12px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 9px 20px;
  border-radius: 17px;
  border: 1px solid #fff;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.works-header li a.active {
  background-color: #fff;
  color: #000;
  opacity: 1 !important;
  pointer-events: none
}

@media (min-width: 769px) {
  .works-header li a:hover {
    opacity: 0.6
  }
}

@media (max-width: 768px) {
  .works-header li a {
    font-size: 2.66667vw;
    padding: 1.2vw 3.2vw 1.2vw 3.2vw;
    border-radius: 8vw
  }
}

.works-list {
  background-color: #222222;
  font-size: 0
}

.works-item {
  width: 50%;
  display: inline-block;
  vertical-align: top
}

@media (max-width: 768px) {
  .works-item {
    width: 100%;
    border-right: none;
    border-top: 1px solid #000
  }
}

.works-item:nth-of-type(2n+1) .works-item-info {
  border-right: 1px solid #000
}

@media (max-width: 768px) {
  .works-item:nth-of-type(2n+1) .works-item-info {
    border-right: none
  }
}

.works-item a {
  display: block;
  overflow: hidden
}

@media (min-width: 769px) {
  .works-item a:hover .works-item__thumbnail {
    opacity: 0.6;
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02)
  }
}

.works-item__thumbnail {
  width: 100%;
  position: relative;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  will-change: transform
}

.works-item__thumbnail:before {
  content: "";
  width: 100%;
  padding-bottom: 55.555555555555556%;
  display: block
}

.works-item__thumbnail img {
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.works-item__thumbnail img.loaded {
  opacity: 1
}

.works-item-info {
  font-size: 11px;
  padding: 50px 56px 0;
  position: relative;
  background-color: #222222
}

@media (min-width: 769px) {
  .works-item-info {
    min-height: 270px
  }
}

@media (max-width: 768px) {
  .works-item-info {
    padding: 6.66667vw 4.66667vw 18.66667vw 4.66667vw
  }
}

.works-item-info__categories {
  margin-bottom: 23px
}

@media (max-width: 768px) {
  .works-item-info__categories {
    margin-bottom: 10px
  }
}

.works-item-info__category {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 9px 20px;
  border-radius: 17px;
  background-color: #333333;
  display: inline-block;
  cursor: pointer;
  color: #fff
}

@media (max-width: 768px) {
  .works-item-info__category {
    margin-right: 1.33333vw;
    margin-bottom: 1.33333vw;
    font-size: 2.66667vw;
    border-radius: 8.26667vw;
    padding: 1.46667vw 3.2vw 1.46667vw 3.2vw
  }
}

.works-item-info__title {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  color: #fff;
  margin: -5px 0
}

@media (max-width: 768px) {
  .works-item-info__title {
    font-size: 3.2vw;
    line-height: 6.13333vw;
    margin: -1.46667vw 0vw -1.46667vw 0vw
  }
}

.works-detail {
  position: relative;
  padding-top: 300px
}

@media (max-width: 1440px) {
  .works-detail {
    padding-top: calc(140px + 11.111111111111111vw)
  }
}

@media (max-width: 768px) {
  .works-detail {
    padding-top: 44vw
  }
}

.works-detail-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0
}

.works-detail-bg.active {
  -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.works-detail-header {
  position: relative;
  padding: 0 80px 110px;
  mix-blend-mode: difference
}

.works-detail-header:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 1440px) {
  .works-detail-header {
    padding-bottom: 7.63889vw
  }
}

@media (max-width: 768px) {
  .works-detail-header {
    padding: 0vw 4.66667vw 12.26667vw 4.66667vw
  }
}

.works-detail-header h1 {
  font-size: 62px;
  line-height: 84px;
  margin: -11px 0;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  float: left
}

@media (min-width: 769px) {
  .works-detail-header h1 {
    max-width: calc(100% - 26.388888888888889vw)
  }
}

@media (max-width: 1680px) {
  .works-detail-header h1 {
    font-size: 3.69vw;
    line-height: 5vw;
    margin: -0.654761904761905vw 0
  }
}

@media (max-width: 1440px) {
  .works-detail-header h1 {
    font-size: 3.47vw;
    line-height: 4.861111111111111vw;
    margin: -0.694444444444444vw 0
  }
}

@media (max-width: 1200px) {
  .works-detail-header h1 {
    font-size: 3.75vw;
    line-height: 5.166666666666667vw;
    margin: -0.75vw 0
  }
}

@media (max-width: 768px) {
  .works-detail-header h1 {
    width: 100%;
    font-size: 6vw;
    line-height: 8.66667vw;
    margin: -1.33333vw 0vw 6.13333vw 0vw
  }
}

.works-detail-header dl {
  font-size: 0;
  float: right;
  width: 290px;
  margin-top: -20px;
  border-bottom: 1px solid #272727
}

@media (max-width: 1440px) {
  .works-detail-header dl {
    width: 20.13889vw;
    margin-top: -1.38889vw
  }
}

@media (max-width: 768px) {
  .works-detail-header dl {
    width: 63.46667vw;
    margin-top: 0;
    float: none
  }
}

.works-detail-header dt {
  display: inline-block;
  width: 90px;
  font-size: 14px;
  color: #666666;
  vertical-align: top;
  padding: 20px 20px 10px 0;
  border-top: 1px solid #272727
}

.works-detail-header dt:first-of-type {
  border: none
}

@media (max-width: 1440px) {
  .works-detail-header dt {
    width: 6.25vw;
    font-size: .97222vw;
    line-height: 1.5;
    padding: 1.38889vw 1.38889vw .69444vw 0vw
  }
}

@media (max-width: 768px) {
  .works-detail-header dt {
    width: 15.33333vw;
    font-size: 2.66667vw;
    line-height: 1.5;
    margin: 0vw 0vw 0vw 0vw;
    padding: 2.93333vw 0vw 1.6vw 0vw
  }
}

.works-detail-header dd {
  display: inline-block;
  font-size: 14px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 20px 0 10px;
  color: #fff;
  width: 200px;
  vertical-align: top;
  border-top: 1px solid #272727
}

.works-detail-header dd:first-of-type {
  border: none
}

@media (max-width: 1440px) {
  .works-detail-header dd {
    width: 13.88889vw;
    font-size: .97222vw;
    line-height: 1.5;
    padding: 1.38889vw 0vw .69444vw 0vw
  }
}

@media (max-width: 768px) {
  .works-detail-header dd {
    width: 48.13333vw;
    font-size: 2.66667vw;
    line-height: 1.5;
    margin: 0vw 0vw 0vw 0vw;
    padding: 2.93333vw 0vw 1.6vw 0vw
  }
}

.works-detail-main {
  width: 100%;
  position: relative;
  overflow: hidden;
  opacity: 0
}

.works-detail-main.active {
  -webkit-transition: opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.works-detail-main:before {
  content: "";
  display: block;
  width: 100vw;
  height: 61.111111111111111vw
}

@media (max-width: 768px) {
  .works-detail-main:before {
    height: 80vw
  }
}

.works-detail-main img {
  width: auto;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.works-detail-main.loaded {
  opacity: 1
}

.works-detail-content {
  position: relative
}

.works-detail-section {
  padding: 150px 80px 0
}

@media (max-width: 768px) {
  .works-detail-section {
    padding: 20vw 4.66667vw 0vw 4.66667vw
  }
}

.works-detail-section__inner {
  max-width: 1024px;
  width: 71.1111111111111vw;
  margin: 0 auto
}

.works-detail-section__inner:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 768px) {
  .works-detail-section__inner {
    width: 100%;
    max-width: 100%
  }
}

.works-detail-section .section-header {
  float: left;
  width: 258px;
  color: #999999;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  margin: -7px 0
}

@media (max-width: 768px) {
  .works-detail-section .section-header {
    float: none;
    width: 100%;
    font-size: 6.66667vw;
    margin: -1.33333vw 0vw 6.4vw 0vw
  }
}

.works-detail-section .section-body {
  float: right;
  width: calc(100% - 258px)
}

@media (max-width: 768px) {
  .works-detail-section .section-body {
    float: none;
    width: 100%
  }
}

.works-detail-section .section-body a {
  color: #000;
  text-decoration: underline
}

@media (min-width: 769px) {
  .works-detail-section .section-body a {
    -webkit-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1)
  }

  .works-detail-section .section-body a:hover {
    opacity: 0.3
  }
}

.works-detail-section .section-body h3 {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.5;
  margin: 72px 0 42px
}

@media (max-width: 768px) {
  .works-detail-section .section-body h3 {
    font-size: 4.66667vw;
    line-height: 6.66667vw;
    margin: 6.66667vw 0vw 4.26667vw 0vw
  }
}

.works-detail-section .section-body h3:first-child {
  margin-top: -8px
}

@media (max-width: 768px) {
  .works-detail-section .section-body h3:first-child {
    margin-top: -1.33333vw
  }
}

.works-detail-section .section-body blockquote {
  background-color: #f5f5f5;
  padding: 2em;
  margin: 0 0 30px
}

.works-detail-section .section-body blockquote p:last-of-type {
  margin: -9px 0
}

@media (max-width: 768px) {
  .works-detail-section .section-body blockquote p:last-of-type {
    margin: -1.46667vw 0vw -1.46667vw 0vw
  }
}

@media (max-width: 768px) {
  .works-detail-section .section-body blockquote {
    margin: 0vw 0vw 6.4vw 0vw
  }
}

.works-detail-section .section-body p {
  font-size: 18px;
  line-height: 2;
  margin: -9px 0 21px
}

@media (max-width: 768px) {
  .works-detail-section .section-body p {
    font-size: 3.46667vw;
    line-height: 6.4vw;
    margin: -1.46667vw 0vw 4.26667vw 0vw
  }
}

.works-detail-section .section-body ul,
.works-detail-section .section-body ol {
  margin-bottom: 21px
}

.works-detail-section .section-body ul li {
  padding-left: 1em;
  position: relative
}

.works-detail-section .section-body ul li:before {
  content: "ãƒ»";
  position: absolute;
  top: 0;
  left: 0
}

.works-detail-section .section-body ol {
  list-style-type: none
}

.works-detail-section .section-body ol li {
  padding-left: 1.5em;
  position: relative
}

.works-detail-section .section-body ol li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0
}

.works-detail-section .section-body ol li:nth-of-type(1):before {
  content: "â‘ "
}

.works-detail-section .section-body ol li:nth-of-type(2):before {
  content: "â‘¡"
}

.works-detail-section .section-body ol li:nth-of-type(3):before {
  content: "â‘¢"
}

.works-detail-section .section-body ol li:nth-of-type(4):before {
  content: "â‘£"
}

.works-detail-section .section-body ol li:nth-of-type(5):before {
  content: "â‘¤"
}

.works-detail-section .section-body ol li:nth-of-type(6):before {
  content: "â‘¥"
}

.works-detail-section .section-body ol li:nth-of-type(7):before {
  content: "â‘¦"
}

.works-detail-section .section-body ol li:nth-of-type(8):before {
  content: "â‘§"
}

.works-detail-section .section-body ol li:nth-of-type(9):before {
  content: "â‘¨"
}

.works-detail-section .section-body ol li:nth-of-type(10):before {
  content: "â‘©"
}

.works-detail-section .section-body li {
  font-size: 18px;
  line-height: 2;
  margin: -9px 0 9px
}

@media (max-width: 768px) {
  .works-detail-section .section-body li {
    font-size: 3.46667vw;
    line-height: 6.4vw;
    margin: -1.46667vw 0vw 3.86667vw 0vw
  }
}

.works-detail-section .section-media-wrapper {
  padding-top: 126px
}

@media (max-width: 768px) {
  .works-detail-section .section-media-wrapper {
    padding-top: 14.26667vw
  }
}

.works-detail-section .section-media {
  clear: both
}

.works-detail-section .section-media.section-media--double {
  margin: 0 -20px 0;
  font-size: 0
}

@media (max-width: 768px) {
  .works-detail-section .section-media.section-media--double {
    margin: 0
  }
}

.media-single {
  width: 100%
}

.media-single img {
  width: 100%;
  height: auto;
  display: block
}

.media-double {
  width: 50%;
  padding: 0 10px 20px;
  display: inline-block
}

@media (max-width: 768px) {
  .media-double {
    width: 100%;
    padding: 0;
    margin-bottom: 2.66667vw
  }

  .media-double:last-of-type {
    margin-bottom: 0
  }
}

.media-double img {
  width: 100%;
  height: auto;
  display: block
}

.section-media.section-media--vimeo {
  width: 100%;
  position: relative
}

@media (min-width: 769px) {
  .section-media.section-media--vimeo .vimeo-playbutton.active .circle {
    width: 90px;
    height: 90px
  }

  .section-media.section-media--vimeo .vimeo-playbutton.active .circle:before {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
  }

  .section-media.section-media--vimeo .vimeo-playbutton.active .text {
    opacity: 0
  }
}

.section-media.section-media--vimeo .vimeo-container {
  position: relative;
  background-color: #000;
  cursor: pointer
}

.section-media.section-media--vimeo .vimeo-container:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 56.25%
}

.section-media.section-media--vimeo .vimeo-caption {
  font-size: 14px;
  line-height: 2;
  margin: -7px 0;
  padding: 14px 0 0;
  width: 100%
}

@media (max-width: 768px) {
  .section-media.section-media--vimeo .vimeo-caption {
    font-size: 3.2vw;
    margin: -1.6vw 0vw -1.6vw 0vw;
    padding: 3.2vw 0vw 0vw 0vw
  }
}

.section-media.section-media--vimeo .vimeo-thumbnail {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden
}

.section-media.section-media--vimeo .vimeo-thumbnail img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.section-media.section-media--vimeo .vimeo-thumbnail:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
  top: 0;
  left: 0
}

.section-media.section-media--vimeo .vimeo-player {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none
}

.section-media.section-media--vimeo .vimeo-player iframe {
  width: 100%
}

.section-media.section-media--vimeo .vimeo-player.active {
  opacity: 1;
  pointer-events: auto
}

.section-media.section-media--vimeo .vimeo-playbutton {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 50px;
  color: #fff;
  letter-spacing: 1px;
  will-change: transform;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .section-media.section-media--vimeo .vimeo-playbutton {
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important
  }
}

.section-media.section-media--vimeo .vimeo-playbutton.disabled {
  display: none
}

.section-media.section-media--vimeo .vimeo-playbutton .text {
  -webkit-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear
}

.section-media.section-media--vimeo .vimeo-playbutton .circle {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.15s cubic-bezier(0.16, 1, 0.3, 1);
  transition: width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.15s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: transform 0.15s cubic-bezier(0.16, 1, 0.3, 1), width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 0.15s cubic-bezier(0.16, 1, 0.3, 1), width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 0.15s cubic-bezier(0.16, 1, 0.3, 1), width 0.5s cubic-bezier(0.16, 1, 0.3, 1), height 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.15s cubic-bezier(0.16, 1, 0.3, 1)
}

.section-media.section-media--vimeo .vimeo-playbutton .circle:before {
  content: "";
  display: block;
  width: 26px;
  height: 18px;
  /* background: url(https://goinc.co.jp/assets/images/common/play-button.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

@media (max-width: 768px) {
  .section-media.section-media--vimeo .vimeo-playbutton {
    top: 50% !important;
    left: 50% !important
  }

  .section-media.section-media--vimeo .vimeo-playbutton .text {
    opacity: 0
  }

  .section-media.section-media--vimeo .vimeo-playbutton .circle {
    width: 10.66667vw;
    height: 10.66667vw;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }

  .section-media.section-media--vimeo .vimeo-playbutton .circle:before {
    width: 13px;
    height: 9px;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1)
  }
}

.works-detail-footer {
  padding: 150px 144px 250px 144px
}

.works-detail-footer:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 768px) {
  .works-detail-footer {
    padding: 20vw 4.66667vw 0vw 4.66667vw
  }
}

.works-detail-link {
  width: 40%;
  float: left
}

@media (max-width: 768px) {
  .works-detail-link {
    width: 100%;
    float: none;
    padding: 0vw 0vw 20vw 0vw;
    font-size: 0
  }
}

.works-detail-link h2 {
  color: #999;
  font-size: 30px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 70px
}

@media (max-width: 768px) {
  .works-detail-link h2 {
    margin: -.53333vw 0vw 5.2vw 0vw;
    font-size: 6.66667vw;
    line-height: 1
  }
}

@media (max-width: 768px) {
  .works-detail-link ul:after {
    content: "";
    display: table;
    clear: both
  }
}

@media (max-width: 768px) {
  .works-detail-link li {
    display: inline-block;
    margin-right: 6.66667vw
  }
}

.works-detail-link li:last-of-type a {
  margin-bottom: 0
}

.works-detail-link a {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #000;
  display: block;
  position: relative;
  line-height: 1;
  padding: 12px 30px 12px 0;
  margin: -12px 0 38px;
  width: 150px;
  overflow: hidden
}

@media (max-width: 768px) {
  .works-detail-link a {
    width: 24vw;
    margin: 0;
    padding: 2.26667vw 0vw 2.26667vw 0vw
  }
}

@media (min-width: 769px) {
  .works-detail-link a {
    -webkit-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1)
  }

  .works-detail-link a:hover {
    opacity: 0.3
  }

  .works-detail-link a:hover span {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.works-detail-link a span {
  width: 100%;
  height: 1px;
  background-color: #000;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0
}

.works-detail-link a:after {
  content: "";
  display: block;
  width: 12px;
  height: 10px;
  /* background: url(https://goinc.co.jp/assets/images/common/blank-icon.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .works-detail-link a:after {
    width: 2.66667vw;
    height: 2.13333vw
  }
}

.works-detail-staff {
  float: left;
  width: 60%;
  padding: 0
}

@media (max-width: 768px) {
  .works-detail-staff {
    width: 100%;
    float: none;
    padding: 0vw 0vw 20vw 0vw
  }
}

.works-detail-staff h2 {
  color: #999;
  font-size: 30px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 70px
}

@media (max-width: 768px) {
  .works-detail-staff h2 {
    margin: -.53333vw 0vw 7.46667vw 0vw;
    font-size: 6.66667vw;
    line-height: 1;
    margin-bottom: 8vw
  }
}

.works-detail-staff ul {
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.works-detail-staff li {
  width: calc(50% - 0.5em);
  display: block;
  vertical-align: top;
  font-size: 14px;
  line-height: 30px;
  margin: -8px 0 24px
}

@media (max-width: 768px) {
  .works-detail-staff li {
    width: 100%;
    font-size: 3.2vw;
    line-height: 5.6vw;
    margin: -1.06667vw 0vw 3.2vw 0vw
  }

  .works-detail-staff li:last-of-type {
    margin-bottom: -1.06667vw
  }
}

.works-detail-staff__post {
  display: block
}

.works-detail-staff__name {
  display: block
}

@media (min-width: 769px) {
  .works-detail-staff--no-link {
    max-width: 1024px;
    width: 71.1111111111111vw;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    float: none
  }

  .works-detail-staff--no-link h2 {
    width: 258px;
    margin: -7px 0
  }

  .works-detail-staff--no-link ul {
    width: calc(100% - 258px)
  }
}

.works-detail-pagelink {
  background-color: #000
}

.works-detail-pagelink:after {
  content: "";
  display: table;
  clear: both
}

.works-pagelink.works-pagelink--blank img {
  width: 100%;
  height: auto;
  display: block
}

.works-pagelink {
  width: 50%;
  float: left;
  color: #fff;
  position: relative;
  background-color: #222;
  overflow: hidden;
  display: block
}

@media (min-width: 769px) {
  .works-pagelink:hover .thumbnail {
    opacity: 0.6;
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02)
  }

  .works-pagelink:hover .text,
  .works-pagelink:hover .prev,
  .works-pagelink:hover .next {
    opacity: 0.7
  }

  .works-pagelink:hover .prev span:before,
  .works-pagelink:hover .next span:before {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

@media (max-width: 768px) {
  .works-pagelink {
    height: 34.666666666666667vw
  }
}

.works-pagelink .thumbnail {
  position: relative;
  -webkit-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1)
}

@media (min-width: 769px) {
  .works-pagelink .thumbnail {
    will-change: opacity, transform
  }
}

@media (max-width: 768px) {
  .works-pagelink .thumbnail {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
  }
}

.works-pagelink .thumbnail img {
  width: 100%;
  height: auto;
  display: block
}

@media (max-width: 768px) {
  .works-pagelink .thumbnail img {
    width: auto;
    height: 34.666666666666667vw
  }
}

.works-pagelink .thumbnail:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65)
}

.works-pagelink .text {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 80px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 1;
  -webkit-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .works-pagelink .text {
    top: 13.33333vw;
    left: 4.66667vw;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%)
  }
}

.works-pagelink--next .text {
  left: auto;
  right: 80px;
  text-align: right
}

@media (max-width: 768px) {
  .works-pagelink--next .text {
    right: 4.66667vw
  }
}

.works-pagelink .next,
.works-pagelink .prev {
  display: block;
  font-size: 14px;
  -webkit-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {

  .works-pagelink .next,
  .works-pagelink .prev {
    font-size: 2.66667vw
  }
}

.works-pagelink .next span,
.works-pagelink .prev span {
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 1px;
  overflow: hidden
}

.works-pagelink .next span:before,
.works-pagelink .prev span:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  /* background: url(https://goinc.co.jp/assets/images/home/works-arrow.svg) top left no-repeat; */
  background-size: cover
}

@media (max-width: 768px) {

  .works-pagelink .next span,
  .works-pagelink .prev span {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.works-pagelink .next {
  padding-right: 24px
}

@media (max-width: 768px) {
  .works-pagelink .next {
    padding-right: 3.46667vw
  }
}

.works-pagelink .next span {
  right: 0
}

.works-pagelink .prev {
  padding-left: 24px
}

@media (max-width: 768px) {
  .works-pagelink .prev {
    padding-left: 3.46667vw
  }
}

.works-pagelink .prev span {
  left: 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}

.works-pagelink .title {
  font-size: 20px;
  line-height: 1.5;
  margin: 0 0 -5px;
  display: block;
  font-weight: bold;
  padding-top: 15px
}

@media (max-width: 768px) {
  .works-pagelink .title {
    font-size: 3.2vw;
    line-height: 4.53333vw;
    margin: 0vw 0vw -.66667vw 0vw;
    padding-top: 2.66667vw
  }
}

.works-detail-pagelink .view-all {
  text-align: center;
  padding: 120px 0;
  clear: both
}

@media (max-width: 768px) {
  .works-detail-pagelink .view-all {
    padding: 14.66667vw 0vw 14.66667vw 0vw
  }
}

.works-detail-pagelink .view-all a {
  background-color: #fff;
  display: inline-block;
  margin: 0 auto;
  color: #000;
  font-family: neue-haas-grotesk-text, 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1;
  font-size: 18px;
  padding: 22px 70px 22px 32px;
  border-radius: 30px;
  position: relative
}

@media (min-width: 769px) {
  .works-detail-pagelink .view-all a {
    -webkit-transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: background-color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1)
  }

  .works-detail-pagelink .view-all a:hover {
    background-color: #999
  }

  .works-detail-pagelink .view-all a:hover span:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

@media (max-width: 768px) {
  .works-detail-pagelink .view-all a {
    font-size: 3.46667vw;
    padding: 4vw 13.33333vw 4vw 5.33333vw;
    border-radius: 12vw
  }
}

.works-detail-pagelink .view-all a span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden
}

@media (max-width: 768px) {
  .works-detail-pagelink .view-all a span {
    width: 2.4vw;
    height: 2.4vw;
    right: 5.33333vw
  }
}

.works-detail-pagelink .view-all a span:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  /* background: url(https://goinc.co.jp/assets/images/home/works-arrow-black.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0
}

@media (max-width: 768px) {
  .works-detail-pagelink .view-all a span:after {
    width: 2.4vw;
    height: 2.4vw
  }
}

.members {
  background-color: #fff;
  padding: 0
}

.members-header {
  position: relative;
  height: 550px;
  padding: 0 0 160px 80px
}

.members-header h1 {
  font-size: 80px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -20px 0 -10px -5px;
  position: absolute;
  bottom: 160px;
  left: 80px;
  line-height: 1
}

@media (max-width: 768px) {
  .members-header h1 {
    font-size: 9.33333vw;
    left: 4.66667vw;
    bottom: 18.66667vw;
    margin: -1.33333vw 0vw -1.33333vw 0vw
  }
}

@media (max-width: 768px) {
  .members-header {
    height: 69.73333vw;
    padding: 0vw 0vw 18.66667vw 4.66667vw
  }
}

.members-image {
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  -webkit-transition: opacity 1s ease-out;
  -o-transition: opacity 1s ease-out;
  transition: opacity 1s ease-out
}

@media (max-width: 768px) {
  .members-image {
    height: 93.33333vw;
    padding-bottom: 8.93333vw
  }
}

.members-image.loaded {
  opacity: 1
}

.member-list {
  padding: 0 0 50px;
  font-size: 0
}

@media (max-width: 768px) {
  .member-list {
    padding: 0vw 0vw 6.66667vw 0vw
  }
}

.member-section__title {
  height: 140px;
  font-size: 25px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 58px 0 0 80px;
  position: relative
}

@media (max-width: 768px) {
  .member-section__title {
    font-size: 4vw;
    padding: 7.73333vw 0vw 0vw 4.66667vw;
    height: 19.33333vw
  }
}

.member-section__title:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  position: absolute;
  top: 0;
  left: 0
}

.member-section__title:after {
  content: "";
  display: block;
  width: 15px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 80px
}

@media (max-width: 768px) {
  .member-section__title:after {
    left: 4.66667vw
  }
}

.member-section__items {
  padding: 0 50px 88px
}

@media (max-width: 768px) {
  .member-section__items {
    padding: 0vw 2vw 10.93333vw 2vw
  }
}

.member-item {
  padding: 0 30px;
  width: 25%;
  display: inline-block;
  margin-bottom: 60px;
  vertical-align: top
}

@media (max-width: 768px) {
  .member-item {
    padding: 0vw 2.66667vw 0vw 2.66667vw;
    margin-bottom: 8.93333vw;
    width: 50%
  }
}

@media (min-width: 769px) {
  .member-item.active.finish .member-item__thumbnail .member-item__color img {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    left: 50%;
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
    transition: -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
    -o-transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);
    transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);
    transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1)
  }

  .member-item.active.finish .member-item__thumbnail .member-item__color:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0
  }

  .member-item.active.finish .member-item__thumbnail:hover .member-item__color {
    -webkit-transform: skew(0deg);
    -ms-transform: skew(0deg);
    transform: skew(0deg);
    left: 0
  }

  .member-item.active.finish .member-item__thumbnail:hover .member-item__color img {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
    left: 50%
  }

  .member-item.active.finish .member-item__thumbnail:hover .member-item__color:after {
    opacity: 1
  }
}

.member-item.active .member-item__gray {
  -webkit-transform: skew(0deg);
  -ms-transform: skew(0deg);
  transform: skew(0deg);
  left: 0;
  opacity: 1
}

.member-item.active .member-item__gray img {
  -webkit-transform: translate3d(-50%, 0px, 0px) skew(0deg);
  transform: translate3d(-50%, 0px, 0px) skew(0deg);
  left: 50%
}

.member-item.active .member-item__color {
  -webkit-transform: skew(0deg);
  -ms-transform: skew(0deg);
  transform: skew(0deg);
  left: 0
}

.member-item.active .member-item__color img {
  opacity: 1;
  -webkit-transform: translate3d(-50%, -50%, 0px) skew(0deg);
  transform: translate3d(-50%, -50%, 0px) skew(0deg);
  left: 50%
}

.member-item.active .member-item__japanese .bg,
.member-item.active .member-item__english .bg {
  color: #000
}

.member-item.active .member-item__japanese .bg:after,
.member-item.active .member-item__english .bg:after {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0)
}

.member-item.active .member-item__post .bg {
  color: #999
}

.member-item.active .member-item__post .bg:after {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0)
}

.member-item__thumbnail {
  background-color: #fff;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  cursor: pointer
}

@media (max-width: 768px) {
  .member-item__thumbnail {
    margin-bottom: 5.33333vw
  }
}

.member-item__thumbnail:before {
  content: "";
  display: block;
  padding-bottom: 116.438356164383562%
}

.member-item__japanese {
  font-size: 20px;
  font-weight: bold;
  margin: -5px 0 10px;
  line-height: 1.5
}

@media (max-width: 768px) {
  .member-item__japanese {
    font-size: 4vw;
    margin: -.93333vw 0vw .8vw 0vw
  }
}

.member-item__english {
  font-size: 14px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -2px 0 10px
}

@media (max-width: 768px) {
  .member-item__english {
    font-size: 2.66667vw;
    margin: -.66667vw 0vw .53333vw 0vw
  }
}

.member-item__post {
  font-size: 12px;
  color: #999;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -2px 0
}

@media (max-width: 768px) {
  .member-item__post {
    font-size: 2.4vw;
    margin: -.53333vw 0vw -.53333vw 0vw
  }
}

.member-item__japanese,
.member-item__english,
.member-item__post {
  position: relative;
  overflow: hidden
}

.member-item__japanese .bg,
.member-item__english .bg,
.member-item__post .bg {
  position: relative;
  opacity: 1;
  display: inline-block;
  color: #fff
}

.member-item__japanese .bg:after,
.member-item__english .bg:after,
.member-item__post .bg:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%
}

.member-item__japanese .fix,
.member-item__english .fix,
.member-item__post .fix {
  display: none
}

.member-item__color,
.member-item__gray {
  position: absolute;
  top: 0;
  left: -120%;
  width: 100%;
  height: 100%;
  -webkit-transform: skew(16deg);
  -ms-transform: skew(16deg);
  transform: skew(16deg);
  overflow: hidden;
  background-color: #fff
}

.member-item__color:before,
.member-item__gray:before {
  content: "";
  display: block;
  padding-bottom: 116.363636363636364%
}

.member-item__color img,
.member-item__gray img {
  position: absolute;
  top: 50%;
  left: 140%;
  width: 100%;
  height: auto;
  -webkit-transform: translate3d(-50%, -50%, 0px) skew(-16deg);
  transform: translate3d(-50%, -50%, 0px) skew(-16deg);
  display: block;
  vertical-align: top
}

.member-item__color img {
  opacity: 0
}

.member-item__gray {
  opacity: 0
}

.member-item__gray img {
  top: 0;
  -webkit-transform: translate3d(-50%, 0px, 0px) skew(-16deg);
  transform: translate3d(-50%, 0px, 0px) skew(-16deg)
}

@media (min-width: 769px) {
  .member-item:nth-of-type(4n+1) .member-item__gray {
    -webkit-transition: opacity 1s linear 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: opacity 1s linear 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    -o-transition: opacity 1s linear 0s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: opacity 1s linear 0s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: opacity 1s linear 0s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s
  }

  .member-item:nth-of-type(4n+1) .member-item__gray img {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s
  }

  .member-item:nth-of-type(4n+1) .member-item__color {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s
  }

  .member-item:nth-of-type(4n+1) .member-item__color img {
    -webkit-transition: opacity 1s linear 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: opacity 1s linear 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    -o-transition: opacity 1s linear 0.5s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: opacity 1s linear 0.5s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: opacity 1s linear 0.5s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s
  }

  .member-item:nth-of-type(4n+1) .member-item__japanese .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s
  }

  .member-item:nth-of-type(4n+1) .member-item__english .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s
  }

  .member-item:nth-of-type(4n+1) .member-item__post .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s
  }

  .member-item:nth-of-type(4n+1) .member-item__japanese .bg {
    -webkit-transition: color 1s linear 0.5s;
    -o-transition: color 1s linear 0.5s;
    transition: color 1s linear 0.5s
  }

  .member-item:nth-of-type(4n+1) .member-item__english .bg {
    -webkit-transition: color 1s linear 0.7s;
    -o-transition: color 1s linear 0.7s;
    transition: color 1s linear 0.7s
  }

  .member-item:nth-of-type(4n+1) .member-item__post .bg {
    -webkit-transition: color 1s linear 0.9s;
    -o-transition: color 1s linear 0.9s;
    transition: color 1s linear 0.9s
  }

  .member-item:nth-of-type(4n+2) .member-item__gray {
    -webkit-transition: opacity 1s linear 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: opacity 1s linear 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    -o-transition: opacity 1s linear 0.4s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: opacity 1s linear 0.4s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: opacity 1s linear 0.4s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s
  }

  .member-item:nth-of-type(4n+2) .member-item__gray img {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s
  }

  .member-item:nth-of-type(4n+2) .member-item__color {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s
  }

  .member-item:nth-of-type(4n+2) .member-item__color img {
    -webkit-transition: opacity 1s linear 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: opacity 1s linear 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    -o-transition: opacity 1s linear 0.9s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: opacity 1s linear 0.9s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: opacity 1s linear 0.9s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s
  }

  .member-item:nth-of-type(4n+2) .member-item__japanese .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s
  }

  .member-item:nth-of-type(4n+2) .member-item__english .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s
  }

  .member-item:nth-of-type(4n+2) .member-item__post .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s
  }

  .member-item:nth-of-type(4n+2) .member-item__japanese .bg {
    -webkit-transition: color 1s linear 0.9s;
    -o-transition: color 1s linear 0.9s;
    transition: color 1s linear 0.9s
  }

  .member-item:nth-of-type(4n+2) .member-item__english .bg {
    -webkit-transition: color 1s linear 1.1s;
    -o-transition: color 1s linear 1.1s;
    transition: color 1s linear 1.1s
  }

  .member-item:nth-of-type(4n+2) .member-item__post .bg {
    -webkit-transition: color 1s linear 1.3s;
    -o-transition: color 1s linear 1.3s;
    transition: color 1s linear 1.3s
  }

  .member-item:nth-of-type(4n+3) .member-item__gray {
    -webkit-transition: opacity 1s linear 0.8s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
    transition: opacity 1s linear 0.8s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
    -o-transition: opacity 1s linear 0.8s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
    transition: opacity 1s linear 0.8s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
    transition: opacity 1s linear 0.8s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s
  }

  .member-item:nth-of-type(4n+3) .member-item__gray img {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s
  }

  .member-item:nth-of-type(4n+3) .member-item__color {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s
  }

  .member-item:nth-of-type(4n+3) .member-item__color img {
    -webkit-transition: opacity 1s linear 1.3s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: opacity 1s linear 1.3s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    -o-transition: opacity 1s linear 1.3s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: opacity 1s linear 1.3s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: opacity 1s linear 1.3s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s
  }

  .member-item:nth-of-type(4n+3) .member-item__japanese .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s
  }

  .member-item:nth-of-type(4n+3) .member-item__english .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.5s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.5s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.5s
  }

  .member-item:nth-of-type(4n+3) .member-item__post .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s
  }

  .member-item:nth-of-type(4n+3) .member-item__japanese .bg {
    -webkit-transition: color 1s linear 1.3s;
    -o-transition: color 1s linear 1.3s;
    transition: color 1s linear 1.3s
  }

  .member-item:nth-of-type(4n+3) .member-item__english .bg {
    -webkit-transition: color 1s linear 1.5s;
    -o-transition: color 1s linear 1.5s;
    transition: color 1s linear 1.5s
  }

  .member-item:nth-of-type(4n+3) .member-item__post .bg {
    -webkit-transition: color 1s linear 1.7s;
    -o-transition: color 1s linear 1.7s;
    transition: color 1s linear 1.7s
  }

  .member-item:nth-of-type(4n) .member-item__gray {
    -webkit-transition: opacity 1s linear 1.2s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
    transition: opacity 1s linear 1.2s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
    -o-transition: opacity 1s linear 1.2s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
    transition: opacity 1s linear 1.2s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
    transition: opacity 1s linear 1.2s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s
  }

  .member-item:nth-of-type(4n) .member-item__gray img {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.2s
  }

  .member-item:nth-of-type(4n) .member-item__color {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s
  }

  .member-item:nth-of-type(4n) .member-item__color img {
    -webkit-transition: opacity 1s linear 1.7s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: opacity 1s linear 1.7s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    -o-transition: opacity 1s linear 1.7s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: opacity 1s linear 1.7s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: opacity 1s linear 1.7s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s
  }

  .member-item:nth-of-type(4n) .member-item__japanese .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.7s
  }

  .member-item:nth-of-type(4n) .member-item__english .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.9s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.9s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.9s
  }

  .member-item:nth-of-type(4n) .member-item__post .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2.1s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2.1s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2.1s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2.1s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2.1s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2.1s
  }

  .member-item:nth-of-type(4n) .member-item__japanese .bg {
    -webkit-transition: color 1s linear 1.7s;
    -o-transition: color 1s linear 1.7s;
    transition: color 1s linear 1.7s
  }

  .member-item:nth-of-type(4n) .member-item__english .bg {
    -webkit-transition: color 1s linear 1.9s;
    -o-transition: color 1s linear 1.9s;
    transition: color 1s linear 1.9s
  }

  .member-item:nth-of-type(4n) .member-item__post .bg {
    -webkit-transition: color 1s linear 2.1s;
    -o-transition: color 1s linear 2.1s;
    transition: color 1s linear 2.1s
  }
}

@media (max-width: 768px) {
  .member-item:nth-of-type(2n+1) .member-item__gray {
    -webkit-transition: opacity 1s linear 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: opacity 1s linear 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    -o-transition: opacity 1s linear 0s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: opacity 1s linear 0s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: opacity 1s linear 0s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s
  }

  .member-item:nth-of-type(2n+1) .member-item__gray img {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s
  }

  .member-item:nth-of-type(2n+1) .member-item__color {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s
  }

  .member-item:nth-of-type(2n+1) .member-item__color img {
    -webkit-transition: opacity 1s linear 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: opacity 1s linear 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    -o-transition: opacity 1s linear 0.5s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: opacity 1s linear 0.5s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: opacity 1s linear 0.5s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s
  }

  .member-item:nth-of-type(2n+1) .member-item__japanese .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s
  }

  .member-item:nth-of-type(2n+1) .member-item__english .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.7s
  }

  .member-item:nth-of-type(2n+1) .member-item__post .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s
  }

  .member-item:nth-of-type(2n+1) .member-item__japanese .bg {
    -webkit-transition: color 1s linear 0.5s;
    -o-transition: color 1s linear 0.5s;
    transition: color 1s linear 0.5s
  }

  .member-item:nth-of-type(2n+1) .member-item__english .bg {
    -webkit-transition: color 1s linear 0.7s;
    -o-transition: color 1s linear 0.7s;
    transition: color 1s linear 0.7s
  }

  .member-item:nth-of-type(2n+1) .member-item__post .bg {
    -webkit-transition: color 1s linear 0.9s;
    -o-transition: color 1s linear 0.9s;
    transition: color 1s linear 0.9s
  }

  .member-item:nth-of-type(2n) .member-item__gray {
    -webkit-transition: opacity 1s linear 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: opacity 1s linear 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    -o-transition: opacity 1s linear 0.4s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: opacity 1s linear 0.4s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: opacity 1s linear 0.4s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s
  }

  .member-item:nth-of-type(2n) .member-item__gray img {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s
  }

  .member-item:nth-of-type(2n) .member-item__color {
    -webkit-transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s
  }

  .member-item:nth-of-type(2n) .member-item__color img {
    -webkit-transition: opacity 1s linear 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: opacity 1s linear 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    -o-transition: opacity 1s linear 0.9s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: opacity 1s linear 0.9s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: opacity 1s linear 0.9s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, left 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s
  }

  .member-item:nth-of-type(2n) .member-item__japanese .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.9s
  }

  .member-item:nth-of-type(2n) .member-item__english .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s
  }

  .member-item:nth-of-type(2n) .member-item__post .bg:after {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    -o-transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1.3s
  }

  .member-item:nth-of-type(2n) .member-item__japanese .bg {
    -webkit-transition: color 1s linear 0.9s;
    -o-transition: color 1s linear 0.9s;
    transition: color 1s linear 0.9s
  }

  .member-item:nth-of-type(2n) .member-item__english .bg {
    -webkit-transition: color 1s linear 1.1s;
    -o-transition: color 1s linear 1.1s;
    transition: color 1s linear 1.1s
  }

  .member-item:nth-of-type(2n) .member-item__post .bg {
    -webkit-transition: color 1s linear 1.3s;
    -o-transition: color 1s linear 1.3s;
    transition: color 1s linear 1.3s
  }
}

.members-joinus {
  background-color: #f5f5f5;
  display: block;
  width: 100%;
  padding: 160px 90px
}

@media (max-width: 768px) {
  .members-joinus {
    padding: 12vw 4.66667vw 12vw 4.66667vw
  }
}

.members-joinus a {
  display: block;
  color: #000;
  padding: 76px;
  position: relative;
  background-color: #f5f5f5;
  border-radius: 10px;
  font-size: 30px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  overflow: hidden;
  cursor: pointer
}

@media (max-width: 768px) {
  .members-joinus a {
    padding: 9.06667vw 7.73333vw 9.06667vw 7.73333vw;
    font-size: 4.66667vw;
    border-radius: 2vw
  }
}

@media (min-width: 769px) {
  .members-joinus a:hover .title {
    color: #999
  }

  .members-joinus a:hover .arrow span {
    opacity: 0.3
  }

  .members-joinus a:hover .arrow span:after {
    -webkit-animation: line 0.5s ease-in-out;
    animation: line 0.5s ease-in-out
  }
}

.members-joinus .bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0
}

.members-joinus .bg:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65)
}

.members-joinus .title {
  position: relative;
  color: #fff;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.members-joinus .arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 64px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .members-joinus .arrow {
    width: 9.33333vw;
    height: 9.33333vw;
    right: 7.73333vw
  }
}

.members-joinus .arrow span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .members-joinus .arrow span {
    width: 2.4vw;
    height: 2.4vw;
    right: 5.33333vw
  }
}

.members-joinus .arrow span:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  /* background: url(https://goinc.co.jp/assets/images/home/works-arrow-black.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0
}

@media (max-width: 768px) {
  .members-joinus .arrow span:after {
    width: 2.4vw;
    height: 2.4vw
  }
}

.members-detail {
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100%;
  height: 100%;
  z-index: 10005;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none
}

.members-detail.show {
  display: block
}

.members-detail.active {
  right: 0;
  pointer-events: auto
}

.members-detail.motion .members-detail__bg {
  opacity: 1
}

.members-detail.motion .members-detail__wrapper {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px)
}

.members-detail__bg {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.members-detail__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #f6f6f6;
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.members-detail__content {
  position: relative;
  height: 100%;
  font-size: 0;
  overflow-y: scroll
}

@media (min-width: 769px) {
  .members-detail__content {
    width: 85.55556vw;
    padding: 9.02778vw 8.88889vw 9.02778vw 8.125vw
  }
}

@media (max-width: 768px) {
  .members-detail__content {
    width: 100vw;
    padding: 4vw 0vw 13.86667vw 4.66667vw
  }
}

.members-detail__info {
  display: inline-block;
  width: 55%;
  vertical-align: top;
  line-height: 1.5;
  padding: 2.36111vw 0vw 0vw 7.77778vw
}

@media (max-width: 768px) {
  .members-detail__info {
    width: 72.53333vw;
    padding: 6.66667vw 0vw 0vw 0vw
  }
}

.members-detail__info.motion .members-detail__name span,
.members-detail__info.motion .members-detail__en span,
.members-detail__info.motion .members-detail__post span,
.members-detail__info.motion .members-detail__text span {
  color: #000
}

.members-detail__info.motion .members-detail__name span:after,
.members-detail__info.motion .members-detail__en span:after,
.members-detail__info.motion .members-detail__post span:after,
.members-detail__info.motion .members-detail__text span:after {
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0)
}

.members-detail__info.motion .members-detail__post span {
  color: #999
}

.members-detail__info.motion .members-detail__links {
  opacity: 1
}

.members-detail__info.active .members-detail__name span,
.members-detail__info.active .members-detail__en span,
.members-detail__info.active .members-detail__post span,
.members-detail__info.active .members-detail__text span {
  -webkit-transition: color 0.5s linear;
  -o-transition: color 0.5s linear;
  transition: color 0.5s linear
}

.members-detail__info.active .members-detail__name span:after,
.members-detail__info.active .members-detail__en span:after,
.members-detail__info.active .members-detail__post span:after,
.members-detail__info.active .members-detail__text span:after {
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.members-detail__info.active .members-detail__links {
  -webkit-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear
}

.members-detail__images {
  display: inline-block;
  width: 45%;
  max-width: 510px;
  overflow: hidden;
  position: relative
}

@media (max-width: 768px) {
  .members-detail__images {
    width: 72.53333vw
  }
}

.members-detail__images img {
  width: auto;
  height: 100%;
  display: block
}

.members-detail__images .image {
  position: absolute;
  top: 0;
  left: -120%;
  width: 100%;
  height: 100%;
  -webkit-transform: skew(16deg);
  -ms-transform: skew(16deg);
  transform: skew(16deg);
  overflow: hidden;
  background-color: #fff;
  -webkit-transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.members-detail__images .image:before {
  content: "";
  display: block;
  padding-bottom: 116.363636363636364%
}

.members-detail__images .image img {
  position: absolute;
  top: 50%;
  left: calc(50% + 33%);
  width: 100%;
  -webkit-transform: translate3d(-50%, -50%, 0px) skew(-16deg);
  transform: translate3d(-50%, -50%, 0px) skew(-16deg);
  display: block;
  opacity: 0;
  -webkit-transition: opacity 0.5s linear, left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.5s linear, left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.members-detail__images .image.active {
  -webkit-transform: skew(0deg);
  -ms-transform: skew(0deg);
  transform: skew(0deg);
  left: 0
}

.members-detail__images .image.active img {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) skew(0deg);
  -ms-transform: translate(-50%, -50%) skew(0deg);
  transform: translate(-50%, -50%) skew(0deg);
  left: 50%
}

.members-detail__name {
  font-size: 35px;
  margin: -9px 0 2px;
  font-weight: bold
}

@media (max-width: 768px) {
  .members-detail__name {
    font-size: 5.33333vw;
    margin: -1.33333vw 0vw .66667vw 0vw
  }
}

.members-detail__en {
  font-size: 25px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -6px 0 6px
}

@media (max-width: 768px) {
  .members-detail__en {
    font-size: 4vw;
    margin: -1.2vw 0vw .8vw 0vw
  }
}

.members-detail__post {
  font-size: 16px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -4px 0 54px;
  color: #999999
}

@media (max-width: 768px) {
  .members-detail__post {
    font-size: 3.2vw;
    margin: -.8vw 0vw 4.53333vw 0vw
  }
}

.members-detail__text {
  font-size: 14px;
  margin: -7px 0;
  line-height: 2
}

.members-detail__text span {}

.members-detail__text span b {
  text-align: left;
  display: inline-block
}

@media (max-width: 768px) {
  .members-detail__text {
    font-size: 3.2vw;
    line-height: 5.6vw;
    margin: -1.2vw 0vw -1.2vw 0vw
  }
}

.members-detail__links {
  padding: 30px 0 0;
  opacity: 0
}

@media (max-width: 768px) {
  .members-detail__links {
    padding-top: 8vw
  }
}

.members-detail__links ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.members-detail__link {
  margin-right: 22px;
  margin-bottom: 22px;
  display: block;
  width: 26px;
  height: 26px;
  /* background: url("../images/members/member-other-icon.png") no-repeat center center; */
  background-size: 26px 26px;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .members-detail__link {
    width: 6.66667vw;
    height: 6.66667vw;
    background-size: 6.4vw 6.4vw;
    margin-right: 5.06667vw;
    margin-bottom: 5.06667vw
  }
}

.members-detail__link--facebook {
  /* background-image: url("../images/members/member-facebook-icon.png") */
}

.members-detail__link--twitter {
  /* background-image: url("../images/members/member-twitter-icon.png") */
}

.members-detail__link--instagram {
  /* background-image: url("../images/members/member-instagram-icon.png") */
}

.members-detail__link--note {
  /* background-image: url("../images/members/member-note-icon.png") */
}

.members-detail__link--other {
  /* background-image: url("../images/members/member-other-icon.png") */
}

.members-detail__link a {
  display: block;
  width: 100%;
  height: 100%
}

@media (min-width: 769px) {
  .members-detail__link:hover {
    opacity: 0.5
  }
}

.members-detail__name,
.members-detail__en,
.members-detail__post,
.members-detail__text {
  position: relative;
  overflow: hidden
}

.members-detail__name span,
.members-detail__en span,
.members-detail__post span,
.members-detail__text span {
  position: relative;
  opacity: 1;
  display: inline-block;
  color: #fff
}

.members-detail__name span:after,
.members-detail__en span:after,
.members-detail__post span:after,
.members-detail__text span:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%
}

.members-detail__close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer
}

@media (max-width: 768px) {
  .members-detail__close-button {
    width: 9.33333vw;
    height: 9.33333vw;
    top: 4vw;
    right: 4vw
  }
}

.members-detail__close-button:before {
  content: "";
  width: 25px;
  height: 3px;
  background-color: #000;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0px) rotate(45deg);
  transform: translate3d(-50%, -50%, 0px) rotate(45deg);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .members-detail__close-button:before {
    -webkit-transform: translate3d(-50%, -50%, 0px) rotate(45deg) scale(0.5);
    transform: translate3d(-50%, -50%, 0px) rotate(45deg) scale(0.5)
  }
}

.members-detail__close-button:after {
  content: "";
  width: 25px;
  height: 3px;
  background-color: #000;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0px) rotate(-45deg);
  transform: translate3d(-50%, -50%, 0px) rotate(-45deg);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .members-detail__close-button:after {
    -webkit-transform: translate3d(-50%, -50%, 0px) rotate(-45deg) scale(0.5);
    transform: translate3d(-50%, -50%, 0px) rotate(-45deg) scale(0.5)
  }
}

@media (min-width: 769px) {
  .members-detail__close-button:hover:before {
    -webkit-transform: translate3d(-50%, -50%, 0px) rotate(135deg);
    transform: translate3d(-50%, -50%, 0px) rotate(135deg);
    background-color: #999
  }

  .members-detail__close-button:hover:after {
    -webkit-transform: translate3d(-50%, -50%, 0px) rotate(45deg);
    transform: translate3d(-50%, -50%, 0px) rotate(45deg);
    background-color: #999
  }
}

.members-detail__prev-button,
.members-detail__next-button {
  position: absolute;
  bottom: 100px;
  right: 30px;
  background-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer
}

@media (max-width: 768px) {

  .members-detail__prev-button,
  .members-detail__next-button {
    width: 9.33333vw;
    height: 9.33333vw;
    bottom: 40vw;
    right: 4vw
  }
}

@media (min-width: 769px) {

  .members-detail__prev-button:hover span,
  .members-detail__next-button:hover span {
    opacity: 0.3
  }

  .members-detail__prev-button:hover span:after,
  .members-detail__next-button:hover span:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.members-detail__prev-button span,
.members-detail__next-button span {
  display: block;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {

  .members-detail__prev-button span,
  .members-detail__next-button span {
    width: 2.4vw;
    height: 2.4vw;
    right: 5.33333vw
  }
}

.members-detail__prev-button span:after,
.members-detail__next-button span:after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  /* background: url(https://goinc.co.jp/assets/images/home/works-arrow-black.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0
}

@media (max-width: 768px) {

  .members-detail__prev-button span:after,
  .members-detail__next-button span:after {
    width: 2.4vw;
    height: 2.4vw
  }
}

.members-detail__prev-button span {
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  -ms-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg)
}

.members-detail__next-button {
  bottom: 30px;
  right: 30px
}

@media (max-width: 768px) {
  .members-detail__next-button {
    bottom: 29.33333vw;
    right: 4vw
  }
}

.members-detail__next-button span {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg)
}

.policy {
  background-color: #fff;
  padding: 180px 0 180px
}

@media (max-width: 768px) {
  .policy {
    padding: 32vw 0vw 26.93333vw 0vw
  }
}

.policy-header {
  padding: 0 80px 180px
}

.policy-header h1 {
  font-size: 50px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -12px 0
}

@media (max-width: 768px) {
  .policy-header h1 {
    font-size: 6.66667vw;
    line-height: 7.33333vw;
    margin: -.8vw 0vw -.8vw 0vw
  }
}

@media (max-width: 768px) {
  .policy-header {
    padding: 0vw 4.66667vw 22.4vw 4.66667vw
  }
}

.policy-header2 {
  padding: 0 80px 80px
}

.policy-header2 h1 {
  font-size: 50px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -12px 0
}

@media (max-width: 768px) {
  .policy-header2 h1 {
    font-size: 6.66667vw;
    line-height: 7.33333vw;
    margin: -.8vw 0vw -.8vw 0vw
  }
}

@media (max-width: 768px) {
  .policy-header2 {
    padding: 0vw 4.66667vw 4.66667vw 4.66667vw
  }
}

.policy-body2 {
  padding: 0 80px
}

@media (max-width: 768px) {
  .policy-body2 {
    padding: 0vw 4.66667vw 0vw 4.66667vw
  }
}

.policy-body {
  padding: 0 208px 0 208px
}

@media (max-width: 768px) {
  .policy-body {
    padding: 0vw 4.66667vw 0vw 4.66667vw
  }
}

.policy-body h2 {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.5;
  margin: -6px 0 24px
}

@media (max-width: 768px) {
  .policy-body h2 {
    font-size: 3.46667vw;
    margin: -1.06667vw 0vw 3.73333vw 0vw
  }
}

.policy-body p {
  font-size: 16px;
  line-height: 28px;
  margin: -6px 0 64px
}

@media (max-width: 768px) {
  .policy-body p {
    font-size: 3.2vw;
    line-height: 5.6vw;
    margin: -1.33333vw 0vw 6.66667vw 0vw
  }
}

.policy-body h3 {
  font-size: 20px;
  color: #666666;
  font-weight: bold;
  line-height: 1.5;
  margin: -5px 0 19px
}

@media (max-width: 768px) {
  .policy-body h3 {
    font-size: 2.66667vw;
    line-height: 5.6vw;
    margin: -1.33333vw 0vw 1.73333vw 0vw
  }
}

.policy-body hr {
  padding-top: 70px;
  border-top: 1px solid #dddddd
}

@media (max-width: 768px) {
  .policy-body hr {
    padding-top: 8vw
  }
}

.policy-body ul {
  margin: -6px 0 64px
}

.policy-body ul li {
  font-size: 16px;
  line-height: 28px
}

@media (max-width: 768px) {
  .policy-body ul {
    margin: -1.33333vw 0vw 6.66667vw 0vw
  }
}

.policy-body figure {
  max-width: 100%;
  margin: 0 0 70px
}

@media (max-width: 768px) {
  .policy-body figure {
    margin: 0vw 0vw 8vw 0vw
  }
}

.policy-body figure img {
  max-width: 100%
}

.policy-body .wp-block-group {
  padding: 70px 0 0;
  border-top: 1px solid #dddddd
}

@media (max-width: 768px) {
  .policy-body .wp-block-group {
    padding-top: 8vw
  }
}

@media (max-width: 768px) {
  .policy-body .wp-block-group p {
    font-size: 3.0vw;
    line-height: 4.8vw;
    margin: -1.6vw 0vw 5.06667vw 0vw
  }
}

.menu-button {
  width: 38px;
  height: 38px;
  padding: 13px 0;
  position: fixed;
  top: 50px;
  right: 80px;
  cursor: pointer;
  mix-blend-mode: difference;
  z-index: 10002;
  will-change: transform;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1)
}

@media (max-width: 768px) {
  .menu-button {
    width: 8vw;
    height: 8vw;
    top: 5.33333vw;
    right: 4.66667vw;
    padding: 2.4vw 0vw 2.4vw 0vw
  }
}

.menu-button span {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-bottom: 8px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .menu-button span {
    margin-bottom: 1.86667vw
  }
}

@media (min-width: 769px) {
  .menu-button:hover span:nth-of-type(1) {
    -webkit-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    transform: translateX(-5px)
  }

  .menu-button:hover span:nth-of-type(2) {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    transform: translateX(5px)
  }
}

.menu-button.active span:nth-of-type(1) {
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px)
}

.menu-button.active span:nth-of-type(2) {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px)
}

@media (max-width: 768px) {
  .menu-button.active span:nth-of-type(1) {
    -webkit-transform: translateY(1.06667vw);
    -ms-transform: translateY(1.06667vw);
    transform: translateY(1.06667vw)
  }

  .menu-button.active span:nth-of-type(2) {
    -webkit-transform: translateY(-1.06667vw);
    -ms-transform: translateY(-1.06667vw);
    transform: translateY(-1.06667vw)
  }
}

@media (min-width: 769px) {
  .menu-button.active.motion:hover span:nth-of-type(1) {
    -webkit-transform: translateY(5px) rotate(45deg);
    -ms-transform: translateY(5px) rotate(45deg);
    transform: translateY(5px) rotate(45deg)
  }

  .menu-button.active.motion:hover span:nth-of-type(2) {
    -webkit-transform: translateY(-5px) rotate(-45deg);
    -ms-transform: translateY(-5px) rotate(-45deg);
    transform: translateY(-5px) rotate(-45deg)
  }
}

html.ie .menu-button span,
html.edge .menu-button span {
  background-color: #888
}

.is-home .menu-button {
  opacity: 0;
  -webkit-transform: translateX(10px) scaleX(0);
  -ms-transform: translateX(10px) scaleX(0);
  transform: translateX(10px) scaleX(0)
}

.is-home.is-fv-finish .menu-button,
.is-home.is-scroll-start .menu-button {
  opacity: 1;
  -webkit-transform: translateX(0px) scaleX(1);
  -ms-transform: translateX(0px) scaleX(1);
  transform: translateX(0px) scaleX(1)
}

.menu-overlay {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #000;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  z-index: 10000;
  opacity: 0;
  pointer-events: none;
  will-change: transform
}

.menu-overlay.active {
  display: block;
  opacity: 1
}

.menu {
  width: 100%;
  height: 100%;
  z-index: 10001;
  position: fixed;
  top: -100vh;
  right: 0;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  overflow: hidden;
  opacity: 0;
  overflow-y: scroll
}

.menu.motion .footer-menu,
.menu.motion .footer-menu2,
.menu.motion .footer__copyright {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.menu.motion .footer-menu2,
.menu.motion .footer__copyright {
  opacity: 1
}

.menu.motion .footer-menu__item {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.menu.motion .menu-logo {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.menu.motion .footer__sns {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.menu .footer-menu2,
.menu .footer__copyright {
  opacity: 0.5;
  -webkit-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  transform: translateX(-15px);
  -webkit-transition: opacity 0.2s linear, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s linear, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: opacity 0.2s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1)
}

.menu .footer-menu {
  -webkit-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  transform: translateX(-15px);
  -webkit-transition: opacity 0.2s linear, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s linear, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: opacity 0.2s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1)
}

.menu .footer__sns {
  opacity: 0.5;
  -webkit-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  transform: translateX(-15px);
  -webkit-transition: opacity 0.2s linear, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s linear, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: opacity 0.2s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 0.2s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1)
}

.menu.active {
  top: 0;
  right: 0;
  opacity: 1
}

.menu .menu-bg {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.menu .menu-logo {
  width: 94.4444444444444vw;
  height: 60%;
  display: block;
  /* background-image: url("https://goinc.co.jp/assets/images/common/menu-logo.svg"); */
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
  -webkit-transition: opacity 0.2s linear 0.1s, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
  transition: opacity 0.2s linear 0.1s, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
  -o-transition: opacity 0.2s linear 0.1s, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
  transition: opacity 0.2s linear 0.1s, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s;
  transition: opacity 0.2s linear 0.1s, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s, -webkit-transform 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0.1s
}

@media (max-width: 768px) {
  .menu .menu-logo {
    height: auto;
    width: 100%;
    height: 50vw;
    /* background-image: url(https://goinc.co.jp/assets/images/common/menu-logo-sp.svg) */
  }
}

.menu .footer-menu {
  float: none;
  margin-bottom: 60px
}

@media (max-width: 768px) {
  .menu .footer-menu {
    margin-bottom: 7.73333vw
  }
}

.menu .footer-menu__item {
  cursor: pointer;
  opacity: 0;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.menu .footer-menu__item--home {
  -webkit-transition: opacity 0.6s ease-out 0s;
  -o-transition: opacity 0.6s ease-out 0s;
  transition: opacity 0.6s ease-out 0s
}

.menu .footer-menu__item--works {
  -webkit-transition: opacity 0.6s ease-out 0.04s;
  -o-transition: opacity 0.6s ease-out 0.04s;
  transition: opacity 0.6s ease-out 0.04s
}

.menu .footer-menu__item--members {
  -webkit-transition: opacity 0.6s ease-out 0.08s;
  -o-transition: opacity 0.6s ease-out 0.08s;
  transition: opacity 0.6s ease-out 0.08s
}

.menu .footer-menu__item--news {
  -webkit-transition: opacity 0.6s ease-out 0.12s;
  -o-transition: opacity 0.6s ease-out 0.12s;
  transition: opacity 0.6s ease-out 0.12s
}

.menu .footer-menu__item--contact {
  -webkit-transition: opacity 0.6s ease-out 0.16s;
  -o-transition: opacity 0.6s ease-out 0.16s;
  transition: opacity 0.6s ease-out 0.16s
}

@media (max-width: 768px) {
  .menu .footer-menu__item {
    margin-bottom: 2.93333vw
  }
}

@media (max-width: 768px) {

  .menu .footer-menu__item span,
  .menu .footer-menu__item a {
    width: 67.46667vw;
    font-size: 34px;
  }
}

.menu .footer-menu2 {
  float: none
}

@media (min-width: 769px) {
  .menu .footer-menu2 {
    margin-bottom: 0;
    padding-bottom: 140px
  }
}

.menu .footer-menu2__item {
  display: block;
  margin-bottom: 15px
}

@media (max-width: 768px) {
  .menu .footer-menu2__item {
    font-size: 4vw;
    padding-right: 4vw;
    margin-bottom: 3.46667vw
  }
}

@media (max-width: 768px) {
  .menu .footer-menu2__item a:before {
    width: 1.33333vw;
    height: 2vw
  }
}

.menu .footer__copyright {
  position: absolute;
  line-height: 1
}

@media (min-width: 769px) {
  .menu .footer__copyright {
    bottom: 60px;
    right: 80px
  }
}

@media (max-width: 768px) {
  .menu .footer__copyright {
    left: 4.66667vw;
    bottom: 5.33333vw
  }
}

.footer__sns {
  position: absolute;
  line-height: 1;
  opacity: 0
}

@media (min-width: 769px) {
  .footer__sns {
    bottom: 60px;
    left: 80px
  }
}

@media (max-width: 768px) {
  .footer__sns {
    left: 4.66667vw;
    bottom: 12vw
  }
}

.menu-inner {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 60px 80px 0
}

@media (min-width: 769px) {
  .menu-inner {
    min-height: 675px
  }
}

@media (max-width: 768px) {
  .menu-inner {
    padding: 10.66667vw 4.26667vw 10.66667vw 4.26667vw
  }
}

.contact {
  background-color: #fff;
  padding: 190px 0 0
}

@media (max-width: 768px) {
  .contact {
    padding: 32vw 0vw 0vw 0vw
  }
}

.contact-other-errors {
  padding: 0 80px 30px
}

@media (max-width: 768px) {
  .contact-other-errors {
    padding: 0vw 4.66667vw 4.66667vw 4.66667vw
  }
}

.contact-other-errors span {
  color: #ff4f4f;
  display: block;
  line-height: 1.5;
  font-size: 16px
}

@media (max-width: 768px) {
  .contact-other-errors span {
    font-size: 3.2vw
  }
}

.contact-header {
  padding: 0 80px 160px
}

.contact-header h1 {
  font-size: 80px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: -20px 0
}

@media (max-width: 768px) {
  .contact-header h1 {
    font-size: 9.33333vw;
    margin: -2.66667vw 0vw -2.66667vw 0vw
  }
}

@media (max-width: 768px) {
  .contact-header {
    padding: 0vw 4.66667vw 24vw 4.66667vw
  }
}

.contact-required-text {
  font-size: 14px;
  line-height: 2;
  margin: -7px 0;
  padding: 22px 0 22px 80px;
  color: #ff0000
}

@media (max-width: 768px) {
  .contact-required-text {
    font-size: 2.66667vw;
    padding: 4vw 0vw 4vw 4.66667vw;
    width: 100%;
    float: none
  }
}

.contact-content {
  width: 100%
}

.contact-content:after {
  content: "";
  display: table;
  clear: both
}

.contact-steps {
  width: 400px;
  float: right;
  border-top: 1px solid #eeeeee;
  padding: 60px 0 0 105px;
  background-color: #fff;
  position: relative
}

@media (max-width: 1440px) {
  .contact-steps {
    padding-left: 7.29167vw
  }
}

@media (max-width: 768px) {
  .contact-steps {
    padding: 5.33333vw 4.66667vw 5.33333vw 4.66667vw;
    float: none;
    width: 100%;
    -webkit-transform: translateY(0px) scale(1) translateZ(0px) !important;
    transform: translateY(0px) scale(1) translateZ(0px) !important
  }
}

.contact-steps.fixed {
  position: absolute;
  float: none;
  top: 0;
  right: 0
}

.contact-steps__inner {
  position: relative
}

.contact-steps__inner:before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background-color: #ccc;
  top: 0;
  left: 24px;
  position: absolute
}

@media (max-width: 768px) {
  .contact-steps__inner:before {
    display: none
  }
}

.contact-steps ul {
  position: relative
}

@media (max-width: 768px) {
  .contact-steps ul {
    font-size: 0
  }

  .contact-steps ul:after {
    content: "";
    display: table;
    clear: both
  }
}

.contact-steps li {
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 16px;
  color: #ccc;
  position: relative
}

@media (max-width: 768px) {
  .contact-steps li {
    font-size: 2.66667vw;
    margin-bottom: 0;
    display: inline-block;
    padding-left: 9.06667vw;
    vertical-align: middle
  }

  .contact-steps li:nth-of-type(1) {
    width: 32.4vw
  }

  .contact-steps li:nth-of-type(2) {
    width: 37.2vw
  }

  .contact-steps li:nth-of-type(3) {
    width: 21.06667vw
  }
}

.contact-steps li.active {
  color: #000
}

.contact-steps li.active .number {
  background-color: #000
}

.contact-steps .attention {
  color: #ff4f4f
}

@media (min-width: 769px) {
  .contact-steps .attention {
    font-size: 12px;
    display: block;
    line-height: 1;
    position: absolute;
    top: 45px;
    left: 73px
  }
}

@media (max-width: 768px) {
  .contact-steps .attention {
    font-size: 2.4vw;
    line-height: 1
  }
}

.contact-steps .number {
  width: 50px;
  height: 50px;
  color: #fff;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  margin-right: 25px
}

@media (max-width: 768px) {
  .contact-steps .number {
    width: 6.66667vw;
    height: 6.66667vw;
    font-size: 3.2vw;
    line-height: 6.66667vw;
    margin-right: 2.4vw;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
  }
}

.contact-form {
  width: calc(100% - 400px);
  float: left;
  border-right: 1px solid #eeeeee;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden
}

@media (max-width: 768px) {
  .contact-form {
    width: 100%;
    float: none
  }
}

.contact-form.contact-form--confirm dd p {
  border: none;
  width: 100%
}

.contact-form.contact-form--confirm dt {
  padding: 50px 0 0 80px
}

@media (max-width: 768px) {
  .contact-form.contact-form--confirm dt {
    padding: 8vw 4.66667vw 3.33333vw 4.66667vw
  }
}

.contact-form.contact-form--confirm dd {
  padding: 50px 80px 50px 0
}

@media (max-width: 768px) {
  .contact-form.contact-form--confirm dd {
    padding: 0vw 4.66667vw 6.66667vw 4.66667vw
  }
}

.contact-form-items {
  font-size: 0;
  padding-bottom: 194px
}

.contact-form-items:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 768px) {
  .contact-form-items {
    padding-bottom: 26.66667vw
  }
}

.contact-form-items dl:after {
  content: "";
  display: table;
  clear: both
}

@media (max-width: 768px) {
  .contact-form-items dl {
    width: 100%
  }
}

.contact-form-items dt {
  width: 270px;
  float: left;
  font-size: 16px;
  border-top: 1px solid #eeeeee;
  vertical-align: top;
  font-weight: bold;
  padding: 78px 0 0 80px
}

@media (max-width: 768px) {
  .contact-form-items dt {
    font-size: 3.2vw;
    border-top: 1px solid #eeeeee;
    padding: 8vw 4.66667vw 3.33333vw 4.66667vw;
    width: 100%;
    float: none
  }
}

.contact-form-items dt.required:after {
  content: "â€»";
  color: #ff4f4f;
  padding-left: 0.5em;
  display: inline-block
}

.contact-form-items dd {
  width: calc(100% - 270px);
  float: left;
  font-size: 16px;
  border-top: 1px solid #eeeeee;
  vertical-align: top;
  padding: 60px 80px 60px 0
}

@media (max-width: 768px) {
  .contact-form-items dd {
    border: none;
    padding: 0vw 4.66667vw 6.66667vw 4.66667vw;
    width: 100%;
    font-size: 3.2vw;
    float: none
  }
}

.contact-form-items dd input[type="text"],
.contact-form-items dd input[type="tel"],
.contact-form-items dd input[type="mail"],
.contact-form-items dd input[type="job2"],
.contact-form-items dd select,
.contact-form-items dd textarea {
  background-color: #eee;
  height: 60px;
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 0 25px;
  font-size: 16px
}

@media (max-width: 768px) {

  .contact-form-items dd input[type="text"],
  .contact-form-items dd input[type="tel"],
  .contact-form-items dd input[type="mail"],
  .contact-form-items dd input[type="job2"],
  .contact-form-items dd select,
  .contact-form-items dd textarea {
    height: 10.66667vw;
    border-radius: 1.33333vw;
    padding: 0vw 4vw 0vw 4vw;
    font-size: 3.2vw
  }
}

.contact-form-items dd .error-text {
  display: none
}

.contact-form-items dd.error input[type="text"],
.contact-form-items dd.error input[type="tel"],
.contact-form-items dd.error input[type="mail"],
.contact-form-items dd.error input[type="job2"],
.contact-form-items dd.error select,
.contact-form-items dd.error textarea {
  background-color: #ffefef
}

.contact-form-items dd.error .error-text {
  font-size: 12px;
  color: #ff4f4f;
  line-height: 2;
  margin: -28px 0 5px;
  display: block
}

@media (max-width: 768px) {
  .contact-form-items dd.error .error-text {
    font-size: 2.66667vw;
    margin: 0 0 5px
  }
}

.contact-form-items dd.error .pulldown-current {
  background-color: #ffefef
}

.contact-form-items dd .contact-select-wrapper {
  background-color: #eee;
  position: relative;
  border-radius: 5px
}

.contact-form-items dd .contact-select-wrapper:before {
  content: "";
  width: 12px;
  height: 7px;
  /* background: url(https://goinc.co.jp/assets/images/contact/pulldown-arrow.svg) top left no-repeat; */
  background-size: contain;
  display: none;
  position: absolute;
  display: block;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 768px) {
  .contact-form-items dd .contact-select-wrapper:before {
    width: 2.66667vw;
    height: 1.46667vw;
    right: 5.33333vw
  }
}

.contact-form-items dd select {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent
}

.contact-form-items dd select::-ms-expand {
  display: none
}

.contact-form-items dd textarea {
  height: auto;
  margin-bottom: 60px;
  padding: 20px 25px
}

@media (max-width: 768px) {
  .contact-form-items dd textarea {
    margin-bottom: 8vw
  }
}

.contact-form-items dd #recaptcha {
  margin-bottom: 60px
}

@media (max-width: 768px) {
  .contact-form-items dd #recaptcha {
    margin-bottom: 8vw
  }
}

.contact-form-items dd .send-button,
.contact-form-items dd .back-button {
  width: 200px;
  background-color: #000;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  padding: 16px 0 17px;
  margin-top: 97px;
  display: inline-block;
  text-align: center
}

@media (max-width: 768px) {

  .contact-form-items dd .send-button,
  .contact-form-items dd .back-button {
    width: 40vw;
    margin-top: 13.33333vw;
    padding: 3.73333vw 0vw 3.73333vw 0vw;
    font-size: 3.2vw;
    border-radius: 12vw;
    line-height: 1
  }
}

.contact-form-items dd .back-button {
  background-color: #666666;
  margin-right: 20px;
  width: 150px;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (min-width: 769px) {
  .contact-form-items dd .back-button:hover {
    opacity: 0.3
  }
}

@media (max-width: 768px) {
  .contact-form-items dd .back-button {
    width: 26.66667vw
  }
}

.contact-form-items dd .send-button {
  background-color: #ccc;
  pointer-events: none
}

.contact-form-items dd .send-button.active {
  background-color: #000;
  pointer-events: auto;
  -webkit-transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (min-width: 769px) {
  .contact-form-items dd .send-button.active:hover {
    background-color: #999
  }
}

.contact-pulldown {
  position: relative;
  width: 100%;
  z-index: 2
}

.contact-pulldown:hover .pulldown-current {
  border-radius: 5px 5px 0 0
}

.contact-pulldown:hover .pulldown-current:before {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg)
}

.contact-pulldown:hover .pulldown-list {
  display: block
}

.contact-pulldown.disabled {
  pointer-events: none
}

.contact-pulldown.disabled .pulldown-list {
  pointer-events: none
}

.contact-pulldown.disabled .pulldown-item {
  pointer-events: none
}

.pulldown-current {
  background-color: #eee;
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 18px 40px 18px 25px;
  font-size: 16px;
  line-height: 1.5;
  min-height: 60px
}

.pulldown-current:before {
  content: "";
  width: 12px;
  height: 7px;
  /* background: url(https://goinc.co.jp/assets/images/contact/pulldown-arrow.svg) top left no-repeat; */
  background-size: contain;
  display: none;
  position: absolute;
  display: block;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (max-width: 768px) {
  .pulldown-current:before {
    width: 2.66667vw;
    height: 1.46667vw;
    right: 5.33333vw
  }
}

.pulldown-list {
  background-color: #e8e8e8;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.pulldown-list__inner {
  position: absolute;
  top: 0;
  left: 0
}

.pulldown-item {
  border: none;
  width: 100%;
  padding: 8px 25px;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer
}

.pulldown-item:first-of-type {
  padding-top: 18px
}

.pulldown-item:last-of-type {
  padding-bottom: 18px
}

.pulldown-item:hover,
.pulldown-item.active {
  font-weight: bold
}

.pulldown-item.active {
  display: none
}

@media (max-width: 768px) {
  .contact-buttons {
    text-align: center
  }
}

.checkbox-wrapper {
  position: relative
}

input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden
}

input[type="checkbox"]:checked+.security-check-label span:before {
  display: block
}

.security-check-label {
  display: block;
  padding-left: 37px;
  position: relative
}

.security-check-label a {
  display: inline-block;
  padding-bottom: 3px;
  position: relative;
  color: #000;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden;
  cursor: pointer
}

.security-check-label a:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #000;
  bottom: 0;
  left: 0
}

@media (min-width: 769px) {
  .security-check-label a:hover {
    opacity: 0.6
  }

  .security-check-label a:hover:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.security-check-label span {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: block
}

.security-check-label span:before {
  content: "";
  width: 22px;
  height: 22px;
  /* background: url(https://goinc.co.jp/assets/images/contact/check-mark.svg) top left no-repeat; */
  background-size: contain;
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

.joinus-description {
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .joinus-description {
    font-size: 3.2vw;
    line-height: 5.6vw
  }
}

.joinus-description p {
  line-height: 2;
  margin: 32px 0 22px;
  display: block
}

@media (max-width: 768px) {
  .joinus-description p {
    line-height: 5.6vw;
    margin: 5.86667vw 0vw 4.93333vw 0vw
  }
}

.joinus-description a {
  color: #000;
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
  overflow: hidden;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@media (min-width: 769px) {
  .joinus-description a:hover {
    opacity: 0.6
  }

  .joinus-description a:hover:after {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

@media (max-width: 768px) {
  .joinus-description a {
    padding-bottom: 1.73333vw
  }
}

.joinus-description a:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #a3a3a3;
  position: absolute;
  bottom: 0;
  left: 0
}

.contact-jobs {
  background-color: #f6f6f6;
  padding: 150px 80px 250px;
  clear: both
}

@media (max-width: 768px) {
  .contact-jobs {
    padding: 14.66667vw 4.66667vw 34vw 4.66667vw
  }
}

.contact-jobs h2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 28px
}

@media (max-width: 768px) {
  .contact-jobs h2 {
    font-size: 5.33333vw;
    margin-bottom: 4vw
  }
}

.contact-jobs h2 span {
  display: inline-block
}

.contact-jobs h2 span:after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #000
}

@media (max-width: 768px) {
  .contact-jobs h2 span:after {
    height: .8vw
  }
}

.contact-jobs dt {
  font-size: 25px;
  font-weight: bold;
  border-top: 1px solid #dddddd;
  margin: 0px 0 27px;
  padding-top: 55px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal
}

@media (max-width: 768px) {
  .contact-jobs dt {
    margin: 0 0;
    font-size: 4vw;
    padding-top: 6.53333vw;
    margin-bottom: 3.86667vw
  }
}

.contact-jobs dt:nth-of-type(1) {
  border: none
}

.contact-jobs dd {
  font-size: 16px;
  line-height: 28px;
  margin: -6px 0 54px;
}

@media (max-width: 768px) {
  .contact-jobs dd {
    font-size: 3.2vw;
    line-height: 4.26667vw;
    margin-bottom: 6.66667vw
  }
}

.contact-jobs dd:last-of-type {
  margin-bottom: 0px
}

.contact-complete {
  width: calc(100% - 400px);
  float: left;
  border-top: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  padding: 90px 80px 270px
}

@media (max-width: 768px) {
  .contact-complete {
    width: 100%;
    padding: 7.73333vw 4.66667vw 33.86667vw 4.66667vw
  }
}

.contact-complete h2 {
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 35px;
  line-height: 1
}

@media (max-width: 768px) {
  .contact-complete h2 {
    font-size: 4.93333vw;
    margin-bottom: 5.33333vw
  }
}

.contact-complete p {
  font-size: 16px;
  line-height: 2;
  margin: -8px 0 82px
}

@media (max-width: 768px) {
  .contact-complete p {
    font-size: 3.2vw;
    line-height: 1.5;
    margin: -.8vw 0vw 7.6vw 0vw
  }
}

.contact-backtohome {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #000;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 12px 30px 12px 0;
  margin: -12px 0 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden
}

@media (max-width: 768px) {
  .contact-backtohome {
    font-size: 2.66667vw;
    padding: 2.13333vw 5.6vw 2.13333vw 0vw;
    margin-top: -1.2vw
  }
}

@media (min-width: 769px) {
  .contact-backtohome:hover {
    opacity: 0.3
  }

  .contact-backtohome:hover .line {
    -webkit-animation: line 0.35s ease-in-out;
    animation: line 0.35s ease-in-out
  }
}

.contact-backtohome .text {
  opacity: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  display: inline-block
}

.contact-backtohome .line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #000;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s ease-in-out;
  -o-transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out;
  transition: background-color 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0
}

.contact-backtohome .arrow {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden
}

@media (max-width: 768px) {
  .contact-backtohome .arrow {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.contact-backtohome .arrow:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  /* background: url(https://goinc.co.jp/assets/images/home/works-arrow-black.svg) top left no-repeat; */
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  -o-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s, -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s
}

@media (max-width: 768px) {
  .contact-backtohome .arrow:before {
    width: 1.86667vw;
    height: 1.86667vw
  }
}

.contact-backtohome.active .line {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1)
}

.contact-backtohome.active .text {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.contact-backtohome.active .arrow:before {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px)
}

.hamburger-button {
  width: 38px;
  height: 38px;
  padding: 13px 0;
  position: absolute;
  top: 54px;
  right: 80px;
  cursor: pointer;
  mix-blend-mode: difference;
  z-index: 10002;
  will-change: transform;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  -o-transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.16, 1, 0.3, 1);
  box-sizing: border-box;

  /* animation */
  opacity: 0;
  -webkit-transform: translateX(10px) scaleX(0);
  -ms-transform: translateX(10px) scaleX(0);
  transform: translateX(10px) scaleX(0);

  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

@media (max-width: 768px) {
  .hamburger-button {
    right: 46px !important;
  }
}

.hamburger-button span {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-bottom: 8px;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  box-sizing: border-box;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(10px) scaleX(0);
    -ms-transform: translateX(10px) scaleX(0);
    transform: translateX(10px) scaleX(0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0px) scaleX(1);
    -ms-transform: translateX(0px) scaleX(1);
    transform: translateX(0px) scaleX(1);
  }
}

@media (min-width: 769px) {
  .hamburger-button:hover span:nth-of-type(1) {
    -webkit-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  .hamburger-button.active:hover span:nth-of-type(1) {
    -webkit-transform: translateY(5px) rotate(45deg);
    -ms-transform: translateY(5px) rotate(45deg);
    transform: translateY(5px) rotate(45deg);
  }

  .hamburger-button.active:hover span:nth-of-type(2) {
    -webkit-transform: translateY(-5px) rotate(-45deg);
    -ms-transform: translateY(-5px) rotate(-45deg);
    transform: translateY(-5px) rotate(-45deg);
  }


  .hamburger-button:hover span:nth-of-type(2) {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    transform: translateX(5px);
  }
}

.hamburger-button.active span:nth-of-type(1) {
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
}

.hamburger-button.active span:nth-of-type(2) {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

.position-fixed {
  position: fixed;
  width: 100vw;
}

.who-we-are .home-works-link,
.our-business .home-works-link {
  color: #000;
}

.who-we-are .home-works-link .arrow:before,
.our-business .home-works-link .arrow:before {
  background: url("https://goinc.co.jp/assets/images/home/works-arrow-black.svg") top left no-repeat;
}

.who-we-are .home-works-link .line {
  background: #000;
}

.news-img {
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  object-position: top center;
  aspect-ratio: 719/403;
  transition: 0.5s ease;
  filter: grayscale();
}

.news-img:hover {
  filter: unset;
}

.news-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: unset;
  text-transform: uppercase;
}
