.custom-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.backBg {
  background: #000
}

.VideoPlayer {
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100% !important;
  height: 100vh !important;
}

.VideoPlayer div iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 116vh;
  border: none;
  transform: translate(-50%, -50%);
  /* pointer-events: none; */
}

@media (max-width: 768px) {
  .VideoPlayer div iframe {
    transform: translate(-50%, -50%) scale(1.5);
  }
}

.VideoPlayer video {
  object-fit: cover !;
}

relative-pos {
  position: relative;
}

@media (max-aspect-ratio: 16 / 9) {
  .Video>div>iframe {
    width: 177.78vh;
  }
}

.switch-wrapper {
  position: absolute;
  top: 58px;
  right: 150px;
  filter: drop-shadow(0 0 3px white);
}

@media (max-width: 768px) {
  .switch-wrapper {
    right: unset;
    left: 46px;
  }
}

.switch {
  position: relative;
  display: inline-block;
}

.switch>span {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked~.off {
  color: #000;
}

input.check-toggle-round-flat:checked~.on {
  color: #fff;
}

.switch>span.on {
  left: 0;
  padding-left: 2px;
  color: #000;
}

.switch>span.off {
  right: 0;
  padding-right: 4px;
  color: #fff;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.check-toggle+label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

input.check-toggle-round-flat+label {
  align-items: center;
  background-color: #fff;
  border-radius: 60px;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 2px;
  width: 80px;
}

input.check-toggle-round-flat+label:before,
input.check-toggle-round-flat+label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat+label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #000;
  border-radius: 60px;
}

input.check-toggle-round-flat+label:after {
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 55%;
  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked+label {}

input.check-toggle-round-flat:checked+label:after {
  margin-left: 37px;
}

.volume {
  --line: #fff;
  --line-width: 6px;
  --duration: .5s;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.volume input {
  display: none;
}

.volume input + svg {
  display: block;
  fill: none;
  stroke: var(--line);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: var(--line-width);
  width: 42px;
  height: 42px;
  mix-blend-mode: difference;
}

.volume input + svg path {
  animation: var(--name) var(--duration) ease forwards;
}

.volume input + svg path:nth-child(2) {
  stroke-dashoffset: 1px;
}

.volume input + svg path:nth-child(3) {
  stroke-dashoffset: 1px;
}

.volume input:checked + svg path:nth-child(1) {
  --name: shape;
}

.volume input:checked + svg path:nth-child(2) {
  --name: small;
}

.volume input:checked + svg path:nth-child(3) {
  --name: large;
}

.volume input:not(:checked) + svg path:nth-child(1) {
  --name: shape-r;
}

.volume input:not(:checked) + svg path:nth-child(2) {
  --name: small-r;
}

.volume input:not(:checked) + svg path:nth-child(3) {
  --name: large-r;
}

@keyframes small {
  0%,
  30% {
      stroke-dasharray: 0 0 30px 64px;
  }
  40% {
      stroke-dashoffset: 16px;
  }
  80%,
  100% {
      stroke-dashoffset: 1px;
  }
  70% {
      stroke-dasharray: 0 43px 30px 64px;
  }
  100% {
      stroke-dasharray: 0 39px 30px 64px;
  }
}

@keyframes small-r {
  0% {
      stroke-dasharray: 0 39px 30px 64px;
  }
  0%,
  40% {
      stroke-dashoffset: 1px;
  }
  70% {
      stroke-dashoffset: 16px;
  }
  70%,
  100% {
      stroke-dasharray: 0 0 30px 64px;
  }
}

@keyframes large {
  0%,
  30% {
      stroke-dasharray: 0 0 50px 84px;
  }
  40% {
      stroke-dashoffset: 16px;
  }
  80%,
  100% {
      stroke-dashoffset: 1px;
  }
  70% {
      stroke-dasharray: 0 82px 32px 84px;
  }
  100% {
      stroke-dasharray: 0 78px 32px 84px;
  }
}

@keyframes large-r {
  0% {
      stroke-dasharray: 0 78px 32px 84px;
  }
  0%,
  40% {
      stroke-dashoffset: 1px;
  }
  70% {
      stroke-dashoffset: 16px;
  }
  70%,
  100% {
      stroke-dasharray: 0 0 50px 84px;
  }
}

@keyframes shape {
  0% {
      stroke-dasharray: 60px 0 184px;
      stroke-dashoffset: 0;
  }
  70% {
      stroke-dasharray: 63px 51px 184px;
      stroke-dashoffset: 21px;
  }
  100% {
      stroke-dasharray: 59px 47px 184px;
      stroke-dashoffset: 17px;
  }
}

@keyframes shape-r {
  0% {
      stroke-dasharray: 59px 47px 184px;
      stroke-dashoffset: 17px;
  }
  100% {
      stroke-dasharray: 60px 0 184px;
      stroke-dashoffset: 0;
  }
}